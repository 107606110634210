import { toJpeg } from "html-to-image";
import $ from "jquery";
import { throttle } from "lodash";

function getSnakeSize() {
  return {
    width: parseInt($(".toolbox-page #snake input[name=width]").val(), 10),
    height: parseInt($(".toolbox-page #snake input[name=height]").val(), 10),
  };
}

function refreshSnakeRatio() {
  const { width, height } = getSnakeSize();

  const $preview = $(".toolbox-page #snake .js-snake-preview > div:first-of-type");
  const previewWidth = $preview.width();

  $preview.css("height", `${Math.round((previewWidth * height) / width)}px`);
}

$(".toolbox-page #snake input[name=width], .toolbox-page #snake input[name=height]").on(
  "change.toolbox",
  refreshSnakeRatio
);

$(".toolbox-page #snake .edith-editor").on(
  "input.toolbox",
  ".edith-visual, .edith-code",
  throttle(
    function (event) {
      const $editor = $(event.currentTarget).closest(".edith-editor");
      const $input = $editor.find("input");

      $(`.toolbox-page #snake .js-snake-preview .${$input.attr("name")}`).html($editor.getEdithEditorContent());
    },
    500,
    { leading: true, trailing: true }
  )
);

$(".toolbox-page #snake input[name=color]").on(
  "change.toolbox",
  throttle(
    function (event) {
      const $input = $(event.currentTarget);

      $(`.toolbox-page #snake .js-snake-preview .cover-obsession figure`).css("backgroundColor", $input.val());
      $(`.toolbox-page #snake .js-snake-preview .cover-episode .snake-1`).css("borderColor", $input.val());
      $(`.toolbox-page #snake .js-snake-preview .cover-episode .overline`).css("color", $input.val());
    },
    500,
    { leading: true, trailing: true }
  )
);

$(".toolbox-page #snake input[name=image]").on("change.toolbox", function (event) {
  const $input = $(event.currentTarget);

  if ($input.get(0).files.length > 0) {
    // Read the File
    const reader = new FileReader();
    reader.readAsDataURL($input.get(0).files[0]);

    // Load the Image inside the Preview
    reader.addEventListener(
      "load",
      function () {
        $(`.toolbox-page #snake .js-snake-preview img`).attr("src", reader.result);
      },
      false
    );
  }
});

$(".toolbox-page #snake select[name=snake]").on("change.toolbox", function (event) {
  const $input = $(event.currentTarget);

  $(`.toolbox-page #snake .js-snake-preview .cover-episode`)
    .removeClass(["snake-bottom", "snake-top"])
    .addClass($input.val());
});

$(".toolbox-page #snake input[name=size]").on("change.toolbox", function (event) {
  const $input = $(event.currentTarget);

  $(`.toolbox-page #snake `).css("--lj-tb-snake-size-factor", $input.val());
});

$(".toolbox-page #snake .js-download-snake").on("click", async function (event) {
  const $button = $(event.currentTarget);
  const { width, height } = getSnakeSize();

  const dataUrl = await toJpeg($button.parents(".js-snake-preview").find(".cover").get(0), {
    backgroundColor: "#00000000",
    canvasWidth: width,
    canvasHeight: height,
    pixelRatio: 1,
    quality: 0.95,
  });

  const link = document.createElement("a");
  link.download = "snake.jpeg";
  link.href = dataUrl;
  link.click();
});

const utmsConfig = Object.freeze([
  Object.freeze({ title: "Mastodon", icon: "fa-brands fa-mastodon", source: "mastodon", medium: "social" }),
  Object.freeze({ title: "Bluesky", icon: "fa-brands fa-bluesky", source: "bluesky", medium: "social" }),
  Object.freeze({ title: "Facebook", icon: "fa-brands fa-facebook", source: "facebook", medium: "social" }),
  Object.freeze({ title: "Twitter", icon: "fa-brands fa-x-twitter", source: "twitter", medium: "social" }),
  Object.freeze({ title: "Instagram", icon: "fa-brands fa-mastodon", source: "instagram", medium: "social" }),
  Object.freeze({ title: "Threads", icon: "fa-brands fa-threads", source: "threads", medium: "social" }),
  Object.freeze({
    title: "Email promotionnel",
    icon: "fa-solid fa-envelope",
    source: "promotion-email",
    medium: "email",
  }),
]);

function inputToURL(input) {
  try {
    const url = new URL(input);
    url.hash = ""; // Drop any hash
    return url;
  } catch (_err) {
    return null;
  }
}

function inputToCampaign(input) {
  if ((input ?? "").match(/^[a-z0-9][a-z0-9-]*[a-z0-9]$/)) {
    return input;
  } else {
    return null;
  }
}

const $utms = $(".toolbox-page #utms");
$utms.find("input[name=url], input[name=campaign]").on("input.toolbox", function () {
  const url = inputToURL($utms.find("input[name=url]").val());
  const campaign = inputToCampaign($utms.find("input[name=campaign]").val());

  // Check if both values are different than null
  if (url === null || campaign === null) {
    // Clean the generated links
    $utms.find(".js-utms-links").empty();
    return;
  }

  // Prepare links
  const linksWithUTMs = utmsConfig.map((utm) => {
    const link = new URL(url);
    link.searchParams.append("utm_source", utm.source);
    link.searchParams.append("utm_medium", utm.medium);
    link.searchParams.append("utm_campaign", campaign);

    return { ...utm, link: link.toString() };
  });

  // Update the generated links
  $utms.find(".js-utms-links").replaceWith($.renderTemplate("marketingToolboxUtmsLinks", { utms: linksWithUTMs }));
});

refreshSnakeRatio();
