import dragula from "dragula";
import $ from "jquery";

const dragulaDataName = "dragulaDrake";
const eventsNamespace = "lesjours.dragula";

// Initialize Dragula Containers
function initializeDragula(domElement) {
  // Create jQuery Element
  const $domElement = $(domElement);

  // Check if the Element is already Initialized
  if ($domElement.data("dragAndDropInitialized") !== undefined) {
    // Skip the Current Element
    return;
  }

  // Initialize the Current Element
  $domElement.data("dragAndDropInitialized", true);

  // Check if the Element is a Multiple Container
  // Construct the array for initialization
  const dragAndDropContainers = [];
  if (undefined !== $domElement.data("multipleContainer")) {
    $domElement
      .find(`[data-drag-and-drop-container=${$domElement.data("multipleContainer")}]`)
      .each((idx, value) => dragAndDropContainers.push(value));
  } else {
    dragAndDropContainers.push(domElement);
  }

  // Initialize Sliders
  const drake = dragula(dragAndDropContainers, {
    mirrorContainer: domElement,
    direction: $(domElement).data("dragAndDropDirection"),
    revertOnSpill: true,
    removeOnSpill: false,
    ignoreInputTextSelection: true,
    moves: function (el, source, handle) {
      // Check if the element is freezed
      if ($(el).data("dragAndDropFreezed") === true) {
        return false;
      }

      // Wrap the handle
      const $handle = $(handle);

      // Check if the source is the first dragula container for the handle
      if (!$handle.closest(".js-drag-and-drop-container").data(dragulaDataName).containers.includes(source)) {
        return false;
      }

      // Check if the handle is a form field
      if ($handle.is("input, textarea, select") || $handle.closest(".edith-editor").length > 0) {
        return false;
      }

      // The element can move
      return true;
    },
    accepts: function (el, target, source, sibling) {
      return $(sibling).data("dragAndDropFreezed") !== true;
    },
  }).on("drop", function (el, target, source, sibling) {
    // Replicate Drop Events to the Container
    $domElement.trigger("dragulaDrop", [el, target, source, sibling]);
  });
  $domElement.data(dragulaDataName, drake);
}

// Update Dragula Containers
function updateDragulaContainers($element) {
  $element.data(dragulaDataName).containers = $element
    .find(`[data-drag-and-drop-container=${$element.data("multipleContainer")}]`)
    .toArray();
}

// ///////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE PLUGIN                                                            //
// ///////////////////////////////////////////////////////////////////////////////////
// JQuery function
$.fn.initializeDragula = function () {
  return this.each((idx, el) => initializeDragula(el));
};
$.fn.updateDragulaContainers = function () {
  return updateDragulaContainers(this);
};

// Listen ajaxFormProcessed Events
$("body").on(`ajaxFormProcessed.${eventsNamespace}`, function () {
  // Initialize Dragula Elements
  $(".js-drag-and-drop-container").each((idx, el) => initializeDragula(el));
});

// Initialize Dragula Elements
$(".js-drag-and-drop-container").each((idx, el) => initializeDragula(el));
