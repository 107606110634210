import $ from "jquery";
import throttle from "lodash/throttle";

// AJAX Search Function
const ajaxSearch = throttle(
  ($input) => {
    // Get Autocomplete Elements
    const $dropdown = $input.parents(".dropdown");
    const $menu = $dropdown.find(".dropdown-menu");
    const endpoint = $input.data("endpoint");

    if (Array.isArray(endpoint)) {
      Promise.all(
        endpoint.map((element) => {
          return $.ajax({
            url: element + "/search" + ($input.data("filters") ? "?" + $input.data("filters") : ""),
            method: "GET",
            data: { request: $input.val() },
            dataType: "html",
            headers: {
              Accept: "text/html",
            },
          });
        })
      ).then((results) => {
        // Replace the Dropdown Menu)
        $menu.html(
          results
            .filter((search) => {
              return search !== "";
            })
            .join("<hr class='dropdown-divider' />")
        );

        // Show the Dropdown
        $dropdown.addClass("show");
        $menu.addClass("show");
      });
    } else {
      // Trigger the Search
      $.ajax({
        url: endpoint + "/search" + ($input.data("filters") ? "?" + $input.data("filters") : ""),
        method: "GET",
        data: { request: $input.val() },
        dataType: "html",
        headers: {
          Accept: "text/html",
        },
      }).done(function (dropdown) {
        // Replace the Dropdown Menu
        $menu.html(dropdown);

        // Show the Dropdown
        $dropdown.addClass("show");
        $menu.addClass("show");
      });
    }
  },
  500,
  { leading: false, trailing: true }
);

// Process a Key Up Event on the Autocomplete Form
function updateAutocomplete(event) {
  // Get Autocomplete Elements
  const $input = $(event.currentTarget);
  const $dropdown = $input.parents(".dropdown");
  const $menu = $dropdown.find(".dropdown-menu");

  // Skip Processing if the Length of the Input is Lower than 2
  if ($input.val().length < 2) {
    return;
  }

  // Check if it's a Key Down/Up
  switch (event.which) {
    // Arrow Up
    case 38:
      // Go to the Last Dropdown Element
      event.preventDefault();
      $menu.children().last().trigger("focus");
      return;
    // Arrow Down
    case 40:
      // Go to the First Dropdown Element
      event.preventDefault();
      $menu.children().first().trigger("focus");
      return;
  }

  // Call the AJAX Search Function
  ajaxSearch($input);
}

// Process a Key Up Event on the Dropdown
function handleDropdownEvent(event) {
  // Get Autocomplete Elements
  const $dropdownEl = $(event.currentTarget);
  const $dropdown = $dropdownEl.parents(".dropdown");

  // Check if it's a Key Down/Up
  switch (event.which) {
    // Arrow Up
    case 38:
      // Go to the Previous Sibling
      event.preventDefault();
      $dropdownEl.prev().trigger("focus");
      return;
    // Arrow Down
    case 40:
      // Go to the Next Sibling
      event.preventDefault();
      $dropdownEl.next().trigger("focus");
      return;
    // Mouse Click (1) or Enter (13)
    case 1:
    case 13:
      // Hide the Dropdown
      event.preventDefault();
      $dropdown.removeClass("show").attr("aria-expanded", "false").find(".dropdown-menu").removeClass("show");

      // Trigger an Event with the Selected Element
      $dropdown.find("input.js-autocomplete").trigger("autocomplete.selected", [$dropdownEl]);
  }
}

// Close Opened Dropdowns
function closeDropdown() {
  $(".dropdown.show").removeClass("show").attr("aria-expanded", "false").find(".dropdown-menu").removeClass("show");
}

// Bind Events Listeners on the Body
$("body")
  .on("keyup.autocomplete", "input.js-autocomplete", updateAutocomplete)
  .on(
    "keyup.autocomplete click.autocomplete",
    ".dropdown:has(input.js-autocomplete) .dropdown-menu > button.dropdown-item",
    handleDropdownEvent
  )
  .on("click.autocomplete", closeDropdown);
