import { replaceAllText } from "@lesjoursfr/browser-tools";
import $ from "jquery";
import debounce from "lodash/debounce";
import moment from "moment";

// Inspired by : https://github.com/steveathon/bootstrap-wysiwyg

// Data key
const wysiwygEditorDataKey = "wysiwygEditor";

// HTML Templates
const tpls = {
  image: function (image) {
    let newString = "\n";
    newString += '<figure contenteditable="false" data-image-map="' + image.id + '">';
    newString += '<img src="' + (image.img ? image.img : image.files[0]) + '" />';
    newString += "<figcaption>";
    newString += "<span>";
    newString += image.title;
    if (image.credit) {
      newString += '<span class="credits">';
      if (image.title) {
        newString += " — ";
      }
      newString += image.credit;
      newString += "</span>";
    }
    newString += "</span>";
    newString += "</figcaption>";
    newString += "</figure>";
    newString += "\n";
    return newString;
  },
  video: function (video, autoplay, loop) {
    let newString = "\n";
    newString +=
      '<figure contenteditable="false" data-video-map="' +
      video.id +
      '" data-video-autoplay="' +
      autoplay +
      '" data-video-loop="' +
      loop +
      '">';
    newString += "<video controls>";
    video.files.forEach(function (source) {
      newString += '<source src="' + source + '" />';
    });
    newString += "</video>";
    newString += "<figcaption>";
    newString += "<span>";
    newString += video.title;
    if (video.credit) {
      newString += '<span class="credits">';
      if (video.title) {
        newString += " — ";
      }
      newString += video.credit;
      newString += "</span>";
    }
    newString += "</span>";
    newString += "</figcaption>";
    newString += "</figure>";
    newString += "\n";
    return newString;
  },
  audio: function (audio) {
    let newString = "\n";
    newString += '<figure contenteditable="false" data-audio-map="' + audio.id + '"';
    newString += ">";
    if (audio.modelName === "PlaylistSoundtrack") {
      newString +=
        '<iframe height="480" width="100%" src="https://www.youtube.com/embed/videoseries?list=' +
        audio.playlists.youtube +
        '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>';
      newString += "</iframe>";
    } else {
      newString += "<audio controls>";
      audio.files.forEach(function (source) {
        newString += '<source src="' + source + '" />';
      });
      newString += "</audio>";
      newString += "<figcaption>";
      newString += "<span>";
      newString += audio.title;
      newString += "</span>";
      if (audio.meta) {
        newString += "<br />";
        newString += '<span class="credits">';
        newString += audio.meta;
        newString += "</span>";
      }
      newString += "</figcaption>";
      newString += "</figure>";
    }
    newString += "\n";
    return newString;
  },
  mixed: function (image, audio, caption, credits) {
    let newString = "\n";
    newString += '<figure contenteditable="false" data-image-map="' + image.id + '" data-audio-map="' + audio.id + '">';
    newString += '<img src="' + (image.img ? image.img : image.files[0]) + '" />';
    newString += "<audio controls>";
    audio.files.forEach(function (source) {
      newString += '<source src="' + source + '" />';
    });
    newString += "</audio>";
    newString += "<figcaption>";
    newString += "<span>";
    newString += caption;
    newString += "</span>";
    newString += "<br />";
    newString += '<span class="credits">';
    newString += credits;
    newString += "</span>";
    newString += "</figcaption>";
    newString += "</figure>";
    newString += "\n";
    return newString;
  },
  youtube: function (videoid) {
    let newString = "\n";
    newString += '<div class="embed-responsive-16by9" data-youtube-videoid="' + videoid + '">';
    newString +=
      '<iframe src="https://www.youtube.com/embed/' +
      videoid +
      '" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">';
    newString += "</iframe>";
    newString += "</div>";
    newString += "\n";
    return newString;
  },
  vimeo: function (videoid) {
    let newString = "\n";
    newString += '<div class="embed-responsive-16by9" data-vimeo-videoid="' + videoid + '">';
    newString +=
      '<iframe src="https://player.vimeo.com/video/' +
      videoid +
      '" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">';
    newString += "</iframe>";
    newString += "</div>";
    newString += "\n";
    return newString;
  },
  facebookvideo: function (videoid) {
    const videoUrl = `https://www.facebook.com/LesJoursfr/videos/${videoid}/`;
    let newString = "\n";
    newString += '<div class="embed-responsive-16by9" data-facebookvideo-videoid="' + videoid + '">';
    newString +=
      '<iframe src="https://www.facebook.com/plugins/video.php?href=' +
      encodeURIComponent(videoUrl) +
      '&show_text=false&t=0" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">';
    newString += "</iframe>";
    newString += "</div>";
    newString += "\n";
    return newString;
  },
  dataviz: function (originalSrc, protectedSrc) {
    let newString = "\n";
    newString +=
      '<figure contenteditable="false" data-original-src="' +
      originalSrc +
      '" data-protected-src="' +
      protectedSrc +
      '">';
    newString += "<figcaption>";
    newString += "<strong>Infographie</strong>";
    newString += "<br />";
    newString += "<span>";
    newString += "URL Abonnés : " + originalSrc;
    newString += "</span>";
    newString += "<br />";
    newString += "<span>";
    newString += "URL Visiteurs : " + protectedSrc;
    newString += "</span>";
    newString += "</figcaption>";
    newString += "</figure>";
    newString += "\n";
    return newString;
  },
};
const splitText = function (text) {
  // Splitted Text on Spaces
  const splitted = text.split(" ");
  const length = splitted.length;
  const half = Math.floor(length / 2.0);

  // Return the Divided Text
  return [splitted.slice(0, half).join(" "), splitted.slice(half).join(" ")];
};
const maxHistory = 30;

/*
 * Function to Normalize the Path of an Internal URL
 * @param {String} input - The URL to Normalize.
 */
function cleanUrl(input) {
  const internalRegExp = /^(?:https?:\/\/)?lesjours\.fr(.*)$/g;
  const obsessionRegExp = /^(\/obsessions\/[a-zA-Z0-9-]+)\/?$/;
  const episodeRegExp = /^(\/obsessions\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+)\/?$/;

  // Function to Normalize the Path of an Internal URL
  function normalizePath(url) {
    if (url.match(obsessionRegExp)) {
      return url.replace(obsessionRegExp, "$1") + "/";
    } else if (url.match(episodeRegExp)) {
      return url.replace(episodeRegExp, "$1") + "/";
    } else if (url === "") {
      return "/";
    } else {
      return url;
    }
  }

  // Check if the URL Start with /
  if (input.startsWith("/")) {
    // Return a Normalized Version of the Path
    return normalizePath(input);
  } else if (input.match(internalRegExp)) {
    // Extract the Path of the URL and Normalize It
    return input.replace(internalRegExp, function (match, urlInternalPart) {
      // Return a Normalized Version of the Path
      return normalizePath(urlInternalPart);
    });
  } else {
    // Return the Un-changed URL
    return input;
  }
}

/**
 * Know if a tag si a self-closing tag
 * @param {String} tagName
 * @returns {Boolean}
 */
function isSelfClosing(tagName) {
  return [
    "AREA",
    "BASE",
    "BR",
    "COL",
    "EMBED",
    "HR",
    "IMG",
    "INPUT",
    "KEYGEN",
    "LINK",
    "META",
    "PARAM",
    "SOURCE",
    "TRACK",
    "WBR",
  ].includes(tagName);
}

/**
 * Remove all empty node’s child nodes regardless of their depth.
 * @param {Node} node the node to process
 */
function removeEmptyNodes(node) {
  // Preserve self-closing tags & empty figure or iframe tags,
  if (
    isSelfClosing(node.tagName) ||
    ["FIGURE", "IFRAME"].includes(node.tagName) ||
    node.classList?.contains("embed-responsive-16by9")
  ) {
    return;
  }

  // Remove the node if it meets the condition
  if (node.nodeType === Node.ELEMENT_NODE && node.textContent.length === 0) {
    node.remove();
    return;
  }

  // Loop through the node’s children
  for (const el of [...node.childNodes]) {
    // Execute the same function if it’s an element node
    removeEmptyNodes(el);
  }
}

/**
 * Remove data-mini or data-note attribute
 * @param {Node} tag
 * @returns {Node}
 */
function removeMiniNoteDataAttr(tag) {
  if (tag.attr("data-mini") !== undefined) {
    tag.removeAttr("data-mini");
  }
  if (tag.attr("data-note") !== undefined) {
    tag.removeAttr("data-note");
  }
}

/**
 * Replace the node by its child nodes.
 * @param {Node} node the node to replace
 * @returns {Array} its child nodes
 */
export function unwrapNode(node) {
  const newNodes = [...node.childNodes];
  node.replaceWith(...newNodes);
  return newNodes;
}

/**
 * Move the Cursor to the position.
 * @param {Node} node The Node where the Range should start.
 * @param {Node} offset An integer greater than or equal to zero representing the offset for the start of the Range from the start of startNode.
 */
function moveTheCursorTo(node, offset) {
  const range = document.createRange();
  const sel = window.getSelection();
  range.setStart(node, offset);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
}

/**
 * Move the Cursor after the given Node.
 * @param {Node} node The Node after which the Range should start.
 */
function moveTheCursorAfter(node) {
  const range = document.createRange();
  const sel = window.getSelection();
  range.setStartAfter(node);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
}

/**
 * Move the Cursor to the end of the given Node.
 * @param {Node} node The Node where the Range should start.
 */
function moveTheCursorToTheEndOf(node) {
  const range = document.createRange();
  const sel = window.getSelection();
  range.selectNodeContents(node);
  range.collapse(false);
  sel.removeAllRanges();
  sel.addRange(range);
}

/**
 * Select the content of the given Node.
 * @param {Node} node The Node whose contents will be selected.
 */
function selectNodeContents(node) {
  const range = document.createRange();
  const sel = window.getSelection();
  range.selectNodeContents(node);
  sel.removeAllRanges();
  sel.addRange(range);
}

/**
 * Select the given Nodes.
 * @param {Array<Node>} nodes The list of Nodes to select.
 */
function selectNodes(nodes) {
  // Check if we just have a self-closing tag
  if (nodes.length === 1 && isSelfClosing(nodes[0].tagName)) {
    moveTheCursorAfter(nodes[0]); // Move the cursor after the Node
    return;
  }

  // Select Nodes
  const range = document.createRange();
  const sel = window.getSelection();
  range.setStartBefore(nodes[0]);
  range.setEndAfter(nodes[nodes.length - 1]);
  sel.removeAllRanges();
  sel.addRange(range);
}

/**
 * Split the node at the caret position.
 * @param {Range} range the caret position
 * @param {Node} node the node to split
 * @returns {Text} the created text node with the caret inside
 */
function splitNodeAtCaret(range, node) {
  // Get the node's parent
  const parent = node.parentNode;

  // Clone the current range & move the starting point to the beginning of the parent's node
  const beforeCaret = range.cloneRange();
  beforeCaret.setStart(parent, 0);

  // Extract the content before the caret
  const frag = beforeCaret.extractContents();

  // Add a TextNode
  const textNode = document.createTextNode("\u200B");
  frag.append(textNode);

  // Add back the content into the node's parent
  parent.prepend(frag);

  // Move the cursor in the created TextNode
  moveTheCursorTo(textNode, 1);

  // Return the inserted TextNode
  return textNode;
}

/**
 * Extract the selection from the node.
 * @param {Range} range the selection to extract
 * @param {Node} node the node to split
 * @param {String} tag the tag to remove
 * @returns {Node} the created node
 */
function extractSelectionFromNode(range, node) {
  // Get the node's parent
  const parent = node.parentNode;

  // Clone the current range & move the starting point to the beginning of the parent's node
  const beforeSelection = new Range();
  beforeSelection.selectNodeContents(parent);
  beforeSelection.setEnd(range.startContainer, range.startOffset);
  const afterSelection = new Range();
  afterSelection.selectNodeContents(parent);
  afterSelection.setStart(range.endContainer, range.endOffset);

  // Extract the content of the selection
  const fragBefore = beforeSelection.extractContents();
  const fragAfter = afterSelection.extractContents();

  // Add back the content into the node's parent
  parent.prepend(fragBefore);
  parent.append(fragAfter);

  // Remove the parent from the selection
  let current = range.commonAncestorContainer;
  while (current.tagName !== node.tagName) {
    // Take the parent
    current = current.parentNode;
  }
  const innerNodes = unwrapNode(current);

  // Preserve the selection
  selectNodes(innerNodes);

  // Return the inserted TextNode
  return range.commonAncestorContainer;
}

/**
 * Represenets an editor
 * @constructor
 * @param {DOMNode} element - The TEXTAREA element to add the Wysiwyg to.
 */
function Wysiwyg(element) {
  // Check if the Element is Empty
  if (element.length === 0) {
    // Skip the Initialisation of the Editor
    return;
  }

  // Initialize the Editor
  this.$editor = element;
  this.$content = this.$editor.find(".wysiwyg-content");
  this.$codeCnt = this.$editor.find(".wysiwyg-codemirror");
  this.$menu = this.$editor.find(".wysiwyg-menu");
  this.$modals = this.$editor.find(".modal");
  this.unsaved = false;
  this.history = [];
  this.histPos = -1;

  // Check if the editor is enable
  if (this.$content.attr("contenteditable") !== "true") {
    // Skip the Initialisation of the Editor
    return;
  }

  // Variable
  const self = this;

  // Listen Key Events
  this.$content
    .on("keydown.wysiwyg", function (e) {
      // Call the Handler
      self.processEvent(e);
    })
    .on("keyup.wysiwyg", function (e) {
      // Call the Handler
      self.processEvent(e);

      // Check if it's a Control/Meta/Alt key or if one of these key is pressed
      if (e.key === "Control" || e.key === "Meta" || e.key === "Alt" || e.ctrlKey || e.metaKey || e.altKey) {
        // Don't trigger a change event
        return;
      }

      // Trigger a "change" event on the Editor
      self.$editor.trigger("change");
    })
    .on("paste.wysiwyg", function (e) {
      // Prevent Default Action
      e.preventDefault();
      e.stopPropagation();

      // Close all Popovers & Contextual menus
      self.closeOpenedPopovers();
      self.closeOpenedContextualMenus();

      // Call the Handler
      self.pasteEvent(e);

      // Clean the Editor Content
      self.cleanEditorContent();

      // Save Content
      self.save();

      // Trigger a "change" event on the Editor
      self.$editor.trigger("change");
    })
    .on("cut.wysiwyg", function () {
      // Close all Popovers & Contextual menus
      self.closeOpenedPopovers();
      self.closeOpenedContextualMenus();

      // Trigger a "change" event on the Editor
      self.$editor.trigger("change");
    })
    .on("dragstart.wysiwyg", function (e) {
      // Get the Parent Element
      let $target = $(e.target);
      const vanillaEvt = e.originalEvent;
      let $targetClone;
      while (!$target.parent().hasClass("wysiwyg-content") && $target.parents(".wysiwyg-content").length > 0) {
        $target = $target.parent();
      }
      $targetClone = $target.clone();

      // Save Dragged Element
      self.draggedElement = $target;

      // Set Dragged Data
      vanillaEvt.dataTransfer.setData("text/html", $target.html());

      // Update the Dragged Image
      $targetClone = $(
        '<div class="wysiwyg-drag-img" style="position:absolute; top:10000px; right: 0px;"></div>'
      ).append($targetClone);
      $("body").append($targetClone);
      vanillaEvt.dataTransfer.setDragImage($targetClone.children().get(0), 0, 0);
    })
    .on("drop.wysiwyg", function (e) {
      // Prevent Default Action
      e.preventDefault();

      // Test if the Target is the Wysiwyg Content
      if ($(e.target).hasClass("wysiwyg-content")) {
        // Don't Move Anything
        return;
      }

      // Get the Parent Element
      let $target = $(e.target);
      while (!$target.parent().hasClass("wysiwyg-content") && $target.parents(".wysiwyg-content").length > 0) {
        $target = $target.parent();
      }

      // Check if we have a Saved Dragged Element
      if (self.draggedElement) {
        $target.after(self.draggedElement);
      }

      // Save Content
      self.save();

      // Trigger a "change" event on the Editor
      self.$editor.trigger("change");
    })
    .on("dragend.wysiwyg", function () {
      // Clean Drag & Drop Informations
      self.draggedElement = undefined;
      $(".wysiwyg-drag-img").remove();
    })
    .on("mouseenter.wysiwyg", "a, figure[data-mini], figure[data-note]", function (e) {
      // Get the jQuery Element
      const $target = $(e.currentTarget);
      const href = $target.attr("href");
      let number;
      let title;
      let content;

      // Check the Link Type
      if ($target.attr("data-note") !== undefined) {
        // It's a Note
        title = "Note";
        number = parseInt($target.attr("data-note"), 10);
        content = $("select[data-editor-link-type=note] option[value=" + number + "]")
          .first()
          .text();
      } else if ($target.attr("data-mini") !== undefined) {
        // It's a Mini
        title = "Enrichissement";
        number = parseInt($target.attr("data-mini"), 10);
        content = $("select[data-editor-link-type=mini] option[value=" + number + "]")
          .first()
          .text();
      } else {
        // It's a Link
        title = "Lien" + ($target.attr("target") === "_blank" ? " (nouvel onglet)" : "");
        content = href;
      }

      // Show a Popover for the Element
      $target
        .popover({
          container: "body",
          title: title,
          content: content,
          placement: $target.is("figure") ? "left" : "top",
        })
        .popover("show");
    })
    .on("mouseleave.wysiwyg", "a, figure[data-mini], figure[data-note]", function (e) {
      // Get the jQuery Element
      const $target = $(e.currentTarget);

      // Destroy the Popover for the Element
      $target.popover("dispose").removeAttr("data-original-title").removeAttr("aria-describedby").removeAttr("title");
    })
    .on("mouseenter.wysiwyg", "> *[id]", function (e) {
      // Get the jQuery Element
      const $target = $(e.currentTarget);

      // Show a Popover for the Element
      $target
        .popover({
          container: "body",
          title: "Ancre",
          content: $target.attr("id"),
          placement: "left",
        })
        .popover("show");
    })
    .on("mouseleave.wysiwyg", "> *[id]", function (e) {
      // Get the jQuery Element
      const $target = $(e.currentTarget);

      // Destroy the Popover for the Element
      $target.popover("dispose").removeAttr("data-original-title").removeAttr("aria-describedby").removeAttr("title");
    });

  // Listen Clics Events on Menu Buttons
  this.$menu.find("button").on("click.wysiwyg", function (e) {
    // Process the Clic Event
    e.preventDefault();
    self.processFunction($(e.currentTarget).data("editor-function"), e);
  });

  // Listen Clics Events on Menu Buttons
  this.$menu
    .find(
      [
        "button[data-modal-target='#editor-insert-link']",
        "button[data-modal-target='#editor-a-insert-link']",
        "button[data-modal-target='#editor-b-insert-link']",
      ].join(", ")
    )
    // eslint-disable-next-line no-unused-vars
    .on("click.wysiwyg", function (e) {
      // Get the Current Position
      const pos = self.getCurrentPosition();

      // Check if there is a Selection
      if (pos.range && !pos.range.collapsed) {
        // Initialize Variable
        let replaced = false;

        // Check if the commonAncestorContainer is a TEXT Node
        if (pos.range.commonAncestorContainer.nodeType === Node.TEXT_NODE) {
          // Check if the Parent is a Link
          const parent = pos.range.commonAncestorContainer.parentNode;
          if (parent.tagName === "A") {
            // Remove the Link from the Element
            $(parent).replaceWith($(parent).html());
            replaced = true;
          }
          // Check if the commonAncestorContainer is an A Tag
        } else if (pos.range.commonAncestorContainer.tagName === "A") {
          // Remove the Link from the Element
          $(pos.range.commonAncestorContainer).replaceWith($(pos.range.commonAncestorContainer).html());
          replaced = true;
          // Try to Replace Links in the Selection
        } else {
          // Check if there is Links in the Selection
          $(pos.range.commonAncestorContainer)
            .find("a")
            .each(function (idx, el) {
              // Check if the the Element Intersect the Selection
              if (pos.sel.containsNode(el, true)) {
                // Remove the Link from the Element
                $(el).replaceWith($(el).html());
                replaced = true;
              }
            });
        }

        // Check if we have added Links
        if (!replaced) {
          // Save the Current Selection
          self.saveSelection();
          const $modal = $(`.modal${$(this).attr("data-modal-target")}`);

          // Clear Mini and Note insertion
          $modal.find(".js-editor-insert-mini select option[value='']").prop("selected", true);
          $modal.find(".js-editor-insert-note select option[value='']").prop("selected", true);

          // Clear Link Input Fields
          $modal.find(".js-editor-insert-url input[type=text]").val("");
          $modal.find(".js-editor-insert-url input[type=checkbox]").prop("checked", true);

          // Manually open the Modal
          $modal.modal("show");
          $modal.find(".nav-link[data-bs-target$='-insert-url']").tab("show");
        }
      }
    });

  // Listen Clics Events on Modals Buttons
  this.$modals.find("button[data-editor-function]").on("click.wysiwyg", function (e) {
    // Process the Clic Event
    e.preventDefault();
    self.processFunction($(e.currentTarget).data("editor-function"), e);
  });

  // Listen Modals Close Events
  this.$modals.on("hidden.bs.modal", function (e) {
    // Remove anything in the figure holder
    $(e.currentTarget).find("[data-figure-holder]").html("");
  });

  // Listen Hide Event on the CTA insertion modal
  this.$editor.find(".modal.editor-insert-cta").on("hide.bs.modal", function (e) {
    const $modal = $(e.currentTarget);

    // Update the Donation input and reset the Link
    const $donationInput = $modal.find("input[name=cta-donation]");
    $donationInput.prop("checked", false);
    $donationInput.trigger("change.episode.cta");

    // Reset the CTA data & the text input
    $modal.removeData("ctaToUpdate");
    $modal.find("input[name=cta-text]").val("");
  });

  // Listen Change Events on Select Elements for Image/Video...
  this.$editor.find("div[data-holder] .js-autocomplete").on("autocomplete.selected", function (event, $selected) {
    // Get Selected Element Information
    const id = $selected.data("id");
    const endpoint = $selected.data("endpoint");
    const dropdown = $selected.parents(".dropdown");

    // Get the Card
    $.ajax({
      url: endpoint + "/figure/" + id + "?" + $(this).data("filters"),
      method: "GET",
      dataType: "html",
      headers: {
        Accept: "text/html",
      },
    }).done(function (card) {
      // Append the Card to the List
      $selected
        .parents(".modal")
        .find("[data-figure-holder=" + dropdown.data("holder") + "]")
        .html(card);
    });
  });

  // Listen Change Events on Select Article
  this.$editor
    .find(".editor-insert-article-link .js-autocomplete")
    .on("autocomplete.selected", function (event, $selected) {
      const $modal = $selected.parents(".modal");

      // Add Information
      $modal.data("id", $selected.data("id")).data("endpoint", $selected.data("endpoint"));

      // Update the Autcomplete value
      $modal.find(".js-autocomplete").val($selected.data("href"));
    });

  // Listen Insert Link Event on Editor
  this.$editor.on("wysiwyg.insertlink", function (event, type, data) {
    self.restoreSelection();

    switch (type) {
      case "minis":
      case "board":
        self.wrapInsideMini(data.number);
        break;
      case "notes":
        self.wrapInsideNote(data.number);
        break;
    }
  });

  // Listen "change" events on the Editor
  this.$editor.on("change.wysiwyg", function () {
    // Check if we already have updated the editor status
    if (self.unsaved) {
      return;
    }

    // Update the Editor Status
    self.unsaved = true;

    // Change the Color of the Save Button to Indicate that there is Unsaved Changes
    self.$menu.find("button[data-editor-function=save]").removeClass("btn-success").addClass("btn-danger");
  });

  // Listen "save" events on the Editor
  this.$editor.on("wysiwyg.save", function () {
    // Check if we already have updated the editor status
    if (!self.unsaved) {
      return;
    }

    // Update the Editor Status
    self.unsaved = false;

    // Change the Color of the Save Button to Indicate that there is Unsaved Changes
    self.$menu.find("button[data-editor-function=save]").removeClass("btn-danger").addClass("btn-success");
  });

  // Firefox Helper to allow Char Insertion into Empty P Tags
  this.$content.on("click.wysiwyg", "p", function (event) {
    // Check if it's a Left Click on an Empty P Tag
    if (event.which !== 1 || $(event.currentTarget).html().trim().length !== 0) {
      return;
    }

    // Move the Cursor inside the Created Tag
    moveTheCursorTo(event.currentTarget, 0);
  });

  // Add Functions to the Editor
  // Return true if the Editor has Unsaved Changes
  element.get(0).isUnsaved = function () {
    // Return the Editor Status
    return self.unsaved;
  };
  // Get the Editor Content
  element.get(0).getContent = function () {
    // Clean the Editor Content
    self.cleanEditorContent();

    // Return the HTML Content
    return self.$content.html();
  };

  // Create a Debounced Version of the Save Function
  this.debouncedSave = debounce(function () {
    // Call the Save Function
    self.save();
  }, 500);

  // First Save
  this.save();

  // Create On-Demand Context Menu
  this.$content.contextMenu({
    selector: " > p, > div.dialogue, > i, > h3, > blockquote, > aside, > figure, > ul, > ol, > div > a.btn-brand",
    build: function ($trigger, e) {
      // Prevent Default
      e.preventDefault();

      // Get the Target Element
      const $clicked = $(e.target);
      let $target = $(e.target);
      while (!$target.parent().hasClass("wysiwyg-content") && $target.parents(".wysiwyg-content").length > 0) {
        $target = $target.parent();
      }

      // Check if we are on a FIGURE Tag
      if ($target.is("figure")) {
        // Generate the Minis Menu
        const minisMenu = {};
        $("[data-ajax-form-key='episode-form-minis-select'] option").each(function (idx, el) {
          minisMenu["mini-" + $(el).val()] = {
            name: $(el).text(),
            icon: "fa-solid fa-anchor",
          };
        });

        // Generate the Notes Menu
        const notesMenu = {};
        $("[data-ajax-form-key='episode-form-notes-select'] option").each(function (idx, el) {
          notesMenu["note-" + $(el).val()] = {
            name: $(el).text(),
            icon: "fa-solid fa-anchor",
          };
        });

        // Generate the Context Menu
        const contextMenu = {
          callback: function (key) {
            // Process the Click Event
            self.processMenuClick(key, $target, $clicked);
          },
          items: {
            remove: {
              name: "Supprimer l’image/la vidéo",
              icon: "fa-solid fa-xmark",
            },
            cutoff: {
              name: !$target.hasClass("cutoff") ? "Arrêter la zone en clair après" : "Rétablir la zone en clair",
              icon: "fa-solid fa-scissors",
            },
            minis: {
              name:
                $target.attr("data-mini") === undefined ? "Ajouter un enrichissement" : "Supprimer l’enrichissement",
              icon: "fa-solid fa-anchor",
              items: $target.attr("data-mini") === undefined ? minisMenu : undefined,
            },
            notes: {
              name: $target.attr("data-note") === undefined ? "Ajouter une note" : "Supprimer la note",
              icon: "fa-solid fa-anchor",
              items: $target.attr("data-note") === undefined ? notesMenu : undefined,
            },
          },
        };

        // Check if the Target already has a mini/note
        if ($target.attr("data-mini") !== undefined || $target.attr("data-note") !== undefined) {
          contextMenu.items.updateLink = {
            name: "Modifier le lien",
            icon: "fa-solid fa-pen-to-square",
          };
        }

        // Check if the Target is an Image
        if ($target.find("img:not([src='/dist/img/drag.png'])").length > 0) {
          contextMenu.items.margins = {
            name: !$target.hasClass("mv-0") ? "Supprimer les marges verticales" : "Rétablir les marges verticales",
            icon: "fa-solid fa-up-down",
          };
          contextMenu.items.legend = {
            name: $target.attr("data-hide-legend") === "true" ? "Afficher la légende" : "Masquer la légende",
            icon: $target.attr("data-hide-legend") === "true" ? "fa-solid fa-toggle-on" : "fa-solid fa-toggle-off",
          };
          contextMenu.items.portraitWhite = {
            name:
              $target.attr("data-portrait") !== "white"
                ? "Activer le mode portrait (fond blanc)"
                : "Désactiver le mode portrait (fond blanc)",
            icon: $target.attr("data-portrait") !== "white" ? "fa-solid fa-toggle-on" : "fa-solid fa-toggle-off",
          };
          contextMenu.items.portraitBlack = {
            name:
              $target.attr("data-portrait") !== "black"
                ? "Activer le mode portrait (fond noir)"
                : "Désactiver le mode portrait (fond noir)",
            icon: $target.attr("data-portrait") !== "black" ? "fa-solid fa-toggle-on" : "fa-solid fa-toggle-off",
          };
          // Check if the Target is a Video
        } else if ($target.find("video").length > 0) {
          contextMenu.items.autoplay = {
            name:
              $target.attr("data-video-autoplay") === "true"
                ? "Lire la vidéo au clic"
                : "Lire automatiquement la vidéo",
            icon:
              $target.attr("data-video-autoplay") === "true"
                ? "fa-regular fa-circle-stop"
                : "fa-regular fa-circle-play",
          };
          contextMenu.items.loop = {
            name: $target.attr("data-video-loop") === "true" ? "Arrêter la vidéo à la fin" : "Lire la vidéo en boucle",
            icon: $target.attr("data-video-loop") === "true" ? "fa-regular fa-circle-stop" : "fa-solid fa-redo-alt",
          };
        }

        // Return the Generated Menu
        return contextMenu;
      }

      // Check if the selection is outside the editor
      const curPos = self.getCurrentPosition();
      if (
        $(curPos.range.startContainer).parents(".wysiwyg-content").length === 0 ||
        $(curPos.range.endContainer).parents(".wysiwyg-content").length === 0
      ) {
        // Prevent the menu to be shown.
        return false;
      }

      // Generate the Context Menu
      const contextMenu = {
        callback: function (key) {
          // Process the Click Event
          self.processMenuClick(key, $target, $clicked);
        },
        items: {},
      };

      // Check if the element is a link
      if ($clicked.is("a.btn-brand")) {
        // Show the removeCta option
        contextMenu.items.removeCta = {
          name: "Supprimer le CTA",
          icon: "fa-solid fa-xmark",
        };
        // Show the updateCta option
        contextMenu.items.updateCta = {
          name: "Modifier le CTA",
          icon: "fa-solid fa-pen-to-square",
        };
      } else if ($clicked.is("a") || $clicked.parent().is("a")) {
        // Show the removeLink option
        contextMenu.items.removeLink = {
          name: "Supprimer le lien",
          icon: "fa-solid fa-link-slash",
        };
        // Show the updateLink option
        contextMenu.items.updateLink = {
          name: "Modifier le lien",
          icon: "fa-solid fa-pen-to-square",
        };
      } else {
        // Show the insertArticleLink option
        contextMenu.items.insertArticleLink = {
          name: "Insérer un lien vers un article",
          icon: "fa-solid fa-newspaper",
        };
      }

      // Check if the element is a paragraph, a dialog or a list
      if ($target.is("p, div.dialogue, ul, ol")) {
        // Check if the element is the last p
        if ($target.is("p:last-of-type")) {
          // Show the hideEnding option
          contextMenu.items.hideEnding = {
            name: $target.hasClass("hide-ending")
              ? "Afficher l’image de fin d’épisode"
              : "Masquer l’image de fin d’épisode",
            icon: $target.hasClass("hide-ending") ? "fa-solid fa-toggle-on" : "fa-solid fa-toggle-off",
          };
        } else {
          // Show the showEnding option
          contextMenu.items.showEnding = {
            name: $target.hasClass("show-ending")
              ? "Masquer l’image de fin d’épisode"
              : "Afficher l’image de fin d’épisode",
            icon: $target.hasClass("show-ending") ? "fa-solid fa-toggle-off" : "fa-solid fa-toggle-on",
          };
        }
      }

      // Check if the element is a dialog
      if ($target.is("div.dialogue") && $clicked.is("p")) {
        // Show the hideDash option
        contextMenu.items.hideDash = {
          name: $clicked.hasClass("hide-dash") ? "Afficher le tiret" : "Masquer le tiret",
          icon: $clicked.hasClass("hide-dash") ? "fa-solid fa-toggle-on" : "fa-solid fa-toggle-off",
        };
        // Show the forceDash option
        contextMenu.items.forceDash = {
          name: $clicked.hasClass("force-dash") ? "Afficher normalement le tiret" : "Forcer l’affichage du tiret",
          icon: $clicked.hasClass("force-dash") ? "fa-solid fa-toggle-off" : "fa-solid fa-toggle-on",
        };
      }

      // Check if the element is a paragraph
      if ($target.is("p, i, h3")) {
        // Show the textAlign option
        contextMenu.items.textAlign = {
          name: "Alignement du texte",
          icon: "fa-solid fa-align-left",
          items: {
            textAlignLeft: { name: "Aligner à gauche", icon: "fa-solid fa-align-left" },
            textAlignCenter: { name: "Aligner au centre", icon: "fa-solid fa-align-center" },
            textAlignRight: { name: "Aligner à droite", icon: "fa-solid fa-align-right" },
            textAlignJustify: { name: "Justifier", icon: "fa-solid fa-align-justify" },
          },
        };
      }

      // Add the other buttons
      contextMenu.items.anchor = {
        name: $target.attr("id") === undefined ? "Ajouter une ancre" : "Supprimer l’ancre",
        icon: "fa-solid fa-anchor",
      };
      contextMenu.items.marginBottom = {
        name: !$target.hasClass("mb-0") ? "Supprimer la marge inférieure" : "Rétablir la marge inférieure",
        icon: "fa-solid fa-up-down",
      };
      contextMenu.items.cutoff = {
        name: !$target.hasClass("cutoff") ? "Arrêter la zone en clair après" : "Rétablir la zone en clair",
        icon: "fa-solid fa-scissors",
      };

      // Return the Generated Menu
      return contextMenu;
    },

    events: {
      hide: function () {
        // Wait 100ms & Remove all empty "class" attributes
        setTimeout(function () {
          self.$content.find('[class=""]').each(function (idx, el) {
            $(el).removeAttr("class");
          });
        }, 100);
      },
    },
  });

  // Store the Wysiwyg in a Data
  $(element).data(wysiwygEditorDataKey, this);
}

// Function used to Close all Popover in the Editor
Wysiwyg.prototype.closeOpenedPopovers = function () {
  // Find all Opened Popovers in the Editor
  this.$content.find('[aria-describedby^="popover"]').popover("dispose");
};

// Function used to Close all Contextual menus in the Editor
Wysiwyg.prototype.closeOpenedContextualMenus = function () {
  // Close all Opened Contextual menus in the Editor
  const $ctxMenus = this.$content.find(".context-menu-active");
  if ($ctxMenus.length > 0) {
    $ctxMenus.contextMenu("hide");
  }
};

// Function used to Clean the Editor
Wysiwyg.prototype.cleanEditorContent = function () {
  // Remove all "span" elements with style inside p tags
  this.$content
    .children("p")
    .find("span[style]")
    .each(function (idx, el) {
      $(el).replaceWith($(el).html());
    });

  // Remove all "style" attributes
  this.$content.find("[style]").each(function (idx, el) {
    $(el).removeAttr("style");
  });

  // Remove all empty "class" attributes
  this.$content.find('[class=""]').each(function (idx, el) {
    $(el).removeAttr("class");
  });

  // Remove all "br" direct children
  this.$content.children("br").remove();

  // Remove empty Tags in the Editor
  for (const el of [...this.$content.get(0).childNodes]) {
    removeEmptyNodes(el);
  }

  // Merge Siblings Tags
  this.$content.children().each(function (idx, el) {
    $(el).html(
      $(el)
        .html()
        .replace(/(<\/b>[\n\r\s]*<b>|<\/i>[\n\r\s]*<i>|<\/u>[\n\r\s]*<u>|<\/s>[\n\r\s]*<s>)/g, " ")
    );
  });

  // Clean the HTML code
  this.$content.html(
    this.$content
      .html()
      // -> Clean soft-hyphen
      .replace(/\u00AD/g, "")
      // -> Remove Browser Added &nbsp; After/Before Opening Tags
      .replace(/(<(?:b|i|u|s|a[^>]+|span)>)&nbsp;/g, " $1")
      .replace(/&nbsp;(<(?:b|i|u|s|a[^>]+|span)>)/g, " $1")
      // -> Remove Browser Added &nbsp; After/Before Closing Tags
      .replace(/(<\/(?:b|i|u|s|a|span)>)&nbsp;/g, "$1 ")
      .replace(/&nbsp;(<\/(?:b|i|u|s|a|span)>)/g, "$1 ")
      // -> Clean added attributes
      .replace(/\s+(data-bs-original-title=""|__fireshotignoredelement)(?=\s+|>)/g, "")
  );

  // Clean the Content
  this.$content.get(0).normalize();
};

// Key Event Listener
Wysiwyg.prototype.processEvent = function (e) {
  // Check if a Meta key is pressed
  const prevent = e.metaKey || e.ctrlKey ? this.processKeyEventWithMeta(e) : this.processKeyEvent(e);

  // Check if we must stop the event here
  if (prevent) {
    e.preventDefault();
    e.stopPropagation();
  }
};

Wysiwyg.prototype.processKeyEvent = function (e) {
  // Check the key code
  switch (e.keyCode) {
    case 8: // Delete : 8
    case 46: // Suppr : 46
      if (e.type === "keyup") {
        // Clean the editor content after keyup : Remove all "span" elements with style inside p tags
        this.$content
          .children("p")
          .find("span[style]")
          .each(function (idx, el) {
            // Check if it's a Non-Breaking Space
            const $el = $(el);
            if ($el.hasClass("wysiwyg-nbsp")) {
              $el.replaceWith('<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>'); // Revert any change to the Element
            } else {
              $el.replaceWith($el.html()); // Clean the Element
            }
          });
        this.debouncedSave(); // Call the Debounced Save
      } else if (e.type === "keydown") {
        // Close all Popovers & Contextual menus
        this.closeOpenedPopovers();
        this.closeOpenedContextualMenus();
      }
      return false; // Let the event bubble
    case 13: // Enter : 13
      if (e.type === "keydown") {
        if (e.shiftKey) {
          this.replaceSelectionByHtml("<br />"); // Insert a line break
        } else {
          this.insertParagraph(); // Insert a new paragraph
        }
      }
      return true;
    default:
      // Save Content after keyup
      if (e.type === "keyup") {
        // Call the Debounced Save
        this.debouncedSave();
      }
  }

  // Return false
  return false;
};

Wysiwyg.prototype.processKeyEventWithMeta = function (e) {
  // Check the key code
  switch (e.keyCode) {
    case 13: // Enter : 13
      if (e.type === "keydown") {
        this.replaceSelectionByHtml("<br />"); // Insert a line break
      }
      return true;
    case 32: // Space : 32
      if (e.type === "keydown") {
        this.replaceSelectionByHtml('<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>'); // Insert a Non-Breaking Space
      }
      return true;
    case 66: // B : 66
      if (e.type === "keydown") {
        this.wrapInsideTag("b"); // Toggle bold
      }
      return true;
    case 73: // I : 73
      if (e.type === "keydown") {
        this.wrapInsideTag("i"); // Toggle italic
      }
      return true;
    case 85: // U : 85
      if (e.type === "keydown") {
        this.wrapInsideTag("u"); // Toggle underline
      }
      return true;
    case 83: // S : 83
      if (e.type === "keydown") {
        this.processFunction("save", e); // Save the Episode
      }
      return true;
    case 89: // Y : 89
      if (e.type === "keydown") {
        this.redo(); // Redo
      }
      return true;
    case 90: // Z : 90
      if (e.type === "keydown") {
        this.undo(); // Undo
      }
      return true;
  }

  // Return false
  return false;
};

// Process a Function
Wysiwyg.prototype.processFunction = function (functionName, event) {
  // Variables
  const $target = $(event.currentTarget);
  const self = this;
  let formGroup;
  let rangeText;
  let rangeCnt;
  let insModal;
  let checkbox;
  let caption;
  let credits;
  let image;
  let images;
  let video;
  let audio;
  let input;
  let node;
  let pos;

  // Check the Function Type
  switch (functionName) {
    case "bold":
      this.wrapInsideTag("b");
      break;
    case "italic":
      this.wrapInsideTag("i");
      break;
    case "quote":
      // Check if there is a Selection
      pos = this.getCurrentPosition();
      if (pos.range.collapsed === true) {
        // Insert the Quote Template
        this.replaceSelectionByHtml(
          '<i>«<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>citation<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>»</i>'
        );
      } else {
        // Insert a Quote based on the Selected Text
        rangeCnt = pos.range.cloneContents();
        this.replaceSelectionByHtml(
          '<i>«<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>' +
            $("<div>").append(rangeCnt).html() +
            '<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>»</i>'
        );
      }
      break;
    case "underline":
      this.wrapInsideTag("u");
      break;
    case "strikethrough":
      this.wrapInsideTag("s");
      break;
    case "subscript":
      this.wrapInsideTag("sub");
      break;
    case "superscript":
      this.wrapInsideTag("sup");
      break;
    case "lettrine":
      // Check if there is a Selection
      pos = this.getCurrentPosition();
      if (pos.range.collapsed === true) {
        // Insert the Lettrine Template
        this.replaceSelectionByHtml('<span class="lettrine">L</span>ettrine');
      } else {
        // Insert a Lettrine based on the Selected Text
        rangeText = pos.range.toString();
        this.replaceSelectionByHtml('<span class="lettrine">' + rangeText.slice(0, 1) + "</span>" + rangeText.slice(1));
      }
      break;
    case "nonbreakingspace":
      this.replaceSelectionByHtml('<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>');
      break;
    case "nobrtag":
      this.wrapInsideTag("nobr");
      break;
    case "auto-nbsp":
      // Normalize Paragraphs
      this.$content.children("p").normalize();

      // Replace multiple spaces & nbsp tags by one space or nbsp tag
      this.$content.html(
        this.$content
          .html()
          .replace(/ {2,}/g, " ")
          .replace(
            /( |<span class="wysiwyg-nbsp" contenteditable="false">(&para;|¶)<\/span>){2,}/g,
            '<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>'
          )
      );

      // Iterate through Paragraphs
      this.$content.find("> p").each(function (idx, el) {
        replaceAllText(el, /«\s/g, '«<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>', false);
        replaceAllText(
          el,
          /\s(»|\?|!|;|:)/g,
          '<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>$1',
          false
        );
        replaceAllText(
          el,
          /(\d)(\s(\d{3}))+/g,
          function (match) {
            return match.replace(/\s/g, '<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>');
          },
          false
        );
        replaceAllText(el, /«([^\s])/g, '«<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>$1', false);
        replaceAllText(
          el,
          /(\S)(»|\?|!|;|:)(?!\S)/g,
          '$1<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>$2',
          false
        );
      });

      // Try to fix Bad Position for Open/Closing Quotes
      this.$content.children("p").each(function (idx, el) {
        // Get the Element
        const $el = $(el);
        let html;

        // Get the HTML Code
        html = $el.html();
        html = html.replace(
          /«(\s|<span class="wysiwyg-nbsp" contenteditable="false">(&para;|¶)<\/span>)*<i>(\s|<span class="wysiwyg-nbsp" contenteditable="false">(&para;|¶)<\/span>)*/g,
          '<i>«<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>'
        );
        html = html.replace(
          /(\s|<span class="wysiwyg-nbsp" contenteditable="false">(&para;|¶)<\/span>)*<\/i>(\s|<span class="wysiwyg-nbsp" contenteditable="false">(&para;|¶)<\/span>)*»/g,
          '<span class="wysiwyg-nbsp" contenteditable="false">&para;</span>»</i>'
        );

        // Update the Element
        $el.html(html);
      });

      // Normalize Paragraphs
      this.$content.children("p").normalize();
      break;
    case "clean-unicode":
      // Remove Browser Added &nbsp;
      this.$content.children("p").each(function (idx, el) {
        // Get the Element
        const $el = $(el);
        let html;

        // Get the HTML Code
        html = $el.html();
        html = html.replace(/\u00AD/g, "");

        // Update the Element
        $el.html(html);
      });

      // Normalize Paragraphs
      this.$content.children("p").normalize();
      break;
    case "paragraph":
      this.insertHtmlInEditorContent("<p></p>");
      break;
    case "citation":
      // Check if there is a Selection
      pos = this.getCurrentPosition();
      if (pos.range.collapsed === true) {
        // Insert the Citation Template
        this.insertHtmlInEditorContent("<blockquote><p>Citation</p><footer>Auteur·rice</footer></blockquote>");
      } else {
        // Insert a Citation based on the Selected Text
        rangeText = splitText(pos.range.toString());
        this.insertHtmlInEditorContent(
          "<blockquote><p>" + rangeText[0] + "</p><footer>" + rangeText[1] + "</footer></blockquote>"
        );
      }
      break;
    case "dialogue":
      this.insertHtmlInEditorContent('<div class="dialogue"><p></p></div>');
      break;
    case "epigraph":
      // Check if there is a Selection
      pos = this.getCurrentPosition();
      if (pos.range.collapsed === true) {
        // Insert the Epigraph Template
        this.insertHtmlInEditorContent("<aside><p><span>Partie 1</span> partie 2</p></aside>");
      } else {
        // Insert an Epigraph based on the Selected Text
        rangeText = splitText(pos.range.toString());
        this.insertHtmlInEditorContent("<aside><p><span>" + rangeText[0] + "</span> " + rangeText[1] + "</p></aside>");
      }
      break;
    case "subhead":
      // Check if there is a Selection
      pos = this.getCurrentPosition();
      if (pos.range.collapsed === true) {
        // Insert the Subhead Template
        this.insertHtmlInEditorContent("<h3>Intertitre</h3>");
      } else {
        // Insert a Subhead based on the Selected Text
        this.insertHtmlInEditorContent("<h3>" + pos.range.toString() + "</h3>");
      }
      break;
    case "update":
      // Juste create a i block at the end of the article
      node = $("<i><b>Mis à jour le " + moment().format("Do MMMM YYYY à HH[h]mm") + ".</b>&#x200b;</i>");
      this.$content.append(node);

      // Move the Cursor to the end the created Node
      moveTheCursorToTheEndOf(node.get(0));

      // Scroll to the Bottom
      this.$content.scrollTop(node.position().top);
      break;
    case "list-ul":
      // Check if there is a Selection
      pos = this.getCurrentPosition();
      if (pos.range.collapsed === true) {
        // Insert the Bulleted List Template
        this.insertHtmlInEditorContent("<ul><li>Liste à puces</li></ul>");
      } else {
        // Insert a Bulleted List based on the Selected Text
        this.insertHtmlInEditorContent("<ul><li>" + pos.range.toString() + "</li></ul>");
      }
      break;
    case "list-ol":
      // Check if there is a Selection
      pos = this.getCurrentPosition();
      if (pos.range.collapsed === true) {
        // Insert the Numbered List Template
        this.insertHtmlInEditorContent("<ol><li>Liste numérotée</li></ol>");
      } else {
        // Insert a Numbered List based on the Selected Text
        this.insertHtmlInEditorContent("<ol><li>" + pos.range.toString() + "</li></ol>");
      }
      break;
    case "hr":
      this.insertHtmlInEditorContent("<hr />");
      break;
    case "hr-small":
      this.insertHtmlInEditorContent('<hr class="small"/>');
      break;
    case "link":
      // Get Parameters
      formGroup = $target.closest(".tab-pane");
      input = formGroup.find("input[data-editor-link-type], select[data-editor-link-type]");

      // Close modal
      $target.closest(".modal").modal("hide");

      // Check if the user has selected something
      if (input.val() === "") {
        // Restore the selection & stop processing the event
        this.restoreSelection();
        return;
      }

      this.restoreSelection();
      switch (input.data("editor-link-type")) {
        case "mini":
          this.wrapInsideMini(input.val());
          break;
        case "note":
          this.wrapInsideNote(input.val());
          break;
        case "url":
          checkbox = formGroup.find("input[type=checkbox]");
          this.wrapInsideLink(cleanUrl(input.val()), checkbox.is(":checked"));
          break;
      }
      break;
    case "article-link":
      // Restore the selection and position
      insModal = $target.parents(".modal");
      self.restoreSelection();
      pos = self.getCurrentPosition();

      // Get the element
      $.ajax({
        url: insModal.data("endpoint") + "/card/" + insModal.data("id"),
        method: "GET",
      }).done(function (card) {
        const href = card.programme
          ? `/podcasts/${card.programme.href}/${card.hrefWithCategory}`
          : `/obsessions/${card.obsession.href}/${card.href}`;

        // Check if we have a selected text
        if (pos.range.collapsed === true) {
          const articleLinkText =
            card.obsession._id === self.$editor.data("obsession")
              ? `(lire l’épisode ${card.number}, <a href="${href}">«&nbsp;${card.title}&nbsp;»</a>)`
              : `(lire l’épisode ${card.number} de la saison ${card.obsession.number}, <a href="${href}">«&nbsp;${card.title}&nbsp;»</a>)`;

          self.replaceSelectionByHtml(articleLinkText);
        } else {
          // Wrap the selection
          self.wrapInsideLink(cleanUrl(href), false);
        }
      });

      // Reset data
      insModal.removeData("endpoint");
      insModal.removeData("id");
      break;
    case "cta":
      insModal = $target.parents(".modal");

      // Create the button
      node = $(document.createElement("a"))
        .addClass("btn-primary btn-brand block no-shadow w-auto")
        .attr("href", insModal.find("input[name=cta-link]").val())
        .html(insModal.find("input[name=cta-text]").val());

      // Add the target attr if needed
      if (insModal.find("input[name=cta-tab-target]").is(":checked")) {
        node.attr("target", "_blank");
      }
      // Add the lj-dbox-button class if needed
      if (insModal.find("input[name=cta-donation]").is(":checked")) {
        node.addClass("lj-dbox-button");
      }

      // Check if it’s a CTA update
      if (insModal.data("ctaToUpdate") !== undefined) {
        // Replace the existing link
        insModal.data("ctaToUpdate").replaceWith(node.get(0));
      } else {
        // Restore the Selection
        self.restoreSelection();

        // Insert the button
        self.insertHtmlInEditorContent($(document.createElement("div")).addClass("row row-around").append(node));
      }

      // Close modal
      insModal.modal("hide");
      break;
    case "image":
      this.$content.get(0).focus();
      this.restoreSelection();
      insModal = $target.parents(".modal");
      image = insModal.find("[data-figure-holder=image] figure");
      this.insertHtmlInEditorContent(tpls.image(image.data("media")));
      break;
    case "images":
      this.$content.get(0).focus();
      this.restoreSelection();
      insModal = $target.parents(".modal");
      images = insModal.find(".multiselect");
      images.data("selectedList").forEach(function (el) {
        self.insertHtmlInEditorContent(tpls.image(images.find("#mselect-" + el).data("media")));
      });
      break;
    case "video":
      this.$content.get(0).focus();
      this.restoreSelection();
      insModal = $target.parents(".modal");
      video = insModal.find("[data-figure-holder=video] figure");
      checkbox = insModal.find("input[type=checkbox]");
      this.insertHtmlInEditorContent(tpls.video(video.data("media"), false, false));
      break;
    case "audio":
      this.$content.get(0).focus();
      this.restoreSelection();
      insModal = $target.parents(".modal");
      audio = insModal.find("[data-figure-holder=soundtrack] figure, [data-figure-holder=soundtrack] iframe");
      this.insertHtmlInEditorContent(tpls.audio(audio.data("media")));
      break;
    case "mixed":
      this.$content.get(0).focus();
      this.restoreSelection();
      insModal = $target.parents(".modal");
      caption = insModal.find("input[data-field-name=caption]").val();
      credits = insModal.find("input[data-field-name=credits]").val();
      image = insModal.find("[data-figure-holder=image]      figure");
      audio = insModal.find("[data-figure-holder=soundtrack] figure");
      this.insertHtmlInEditorContent(tpls.mixed(image.data("media"), audio.data("media"), caption, credits));
      break;
    case "youtube":
      this.$content.get(0).focus();
      this.restoreSelection();
      insModal = $target.parents(".modal");
      this.insertHtmlInEditorContent(tpls.youtube(insModal.find("input[name=videoid]").val()));
      break;
    case "vimeo":
      this.$content.get(0).focus();
      this.restoreSelection();
      insModal = $target.parents(".modal");
      this.insertHtmlInEditorContent(tpls.vimeo(insModal.find("input[name=videoid]").val()));
      break;
    case "facebookvideo":
      this.$content.get(0).focus();
      this.restoreSelection();
      insModal = $target.parents(".modal");
      this.insertHtmlInEditorContent(tpls.facebookvideo(insModal.find("input[name=videoid]").val()));
      break;
    case "dataviz":
      this.$content.get(0).focus();
      this.restoreSelection();
      insModal = $target.parents(".modal");
      this.insertHtmlInEditorContent(
        tpls.dataviz(
          insModal.find("input[data-field-name=original-src]").val(),
          insModal.find("input[data-field-name=protected-src]").val()
        )
      );
      break;
    case "code":
      // Check if we are in WYSIWYG Mode
      if (this.$content.is(":visible")) {
        // Clean the Editor Content
        this.cleanEditorContent();

        // Display the Code Editor
        this.$codeCnt.removeClass("d-none");
        this.$content.addClass("d-none");

        // Disable Wysiwyg Buttons
        this.$menu.find(".btn-group:not(.float-end)").addClass("d-none");

        // Activate the Code Editor
        this.CodeMirror = this.$codeCnt.find(".codemirror-holder").createCodeMirror();
        this.CodeMirror.setCodeMirrorDoc(this.$content.html());
      } else if (this.CodeMirror !== undefined) {
        // Get the Editor Content & Add it to the Wysiwyg Editor
        this.$content.html(this.CodeMirror.getCodeMirrorDoc());

        // Drop the CodeMirror Editor
        this.CodeMirror.destroyCodeMirror();
        this.CodeMirror = undefined;
        this.$codeCnt.find(".codemirror-holder").children().remove();

        // Display the Wysiwyg Editor
        this.$content.removeClass("d-none");
        this.$codeCnt.addClass("d-none");

        // Enable Wysiwyg Buttons
        this.$menu.find(".btn-group:not(.float-end)").removeClass("d-none");
      }

      // Reset the History
      this.histPos = -1;
      this.save();

      // Stop Event Process
      return;
    case "save":
      // Check if the CodeMirror Editor is Activated
      if (!this.$codeCnt.hasClass("d-none")) {
        // Switch back to the Wysiwyg Editor
        this.processFunction("code", event);
      }

      // Close all Popovers & Contextual menus
      this.closeOpenedPopovers();
      this.closeOpenedContextualMenus();

      // Clean the Editor Content
      this.cleanEditorContent();

      // Trigger an Event on the Editor with the HTML Content
      this.$editor.trigger(
        "wysiwyg.save",
        this.$content.html().replace(/(?:[\r\n\t\s]+[\r\n]|[\r\n][\r\n\t\s]+)/gm, "\n")
      );

      // Stop Event Process
      return;
    default:
      // Save the Current Selection
      this.saveSelection();
  }

  // Save Content
  this.save();

  // Trigger a "change" event on the Editor
  this.$editor.trigger("change");
};

// Process Click Event on the Context Menu
Wysiwyg.prototype.processMenuClick = function (functionName, $target, $clicked) {
  // Check if we are trying to add/remove a Mini
  if (/mini/.test(functionName)) {
    // Check if we want to remove the Mini
    if (functionName === "minis") {
      // Remove the Mini
      $target.removeAttr("data-mini");
    } else {
      // Check if we already have a Note
      if ($target.attr("data-note") !== undefined) {
        // Remove the Note
        $target.removeAttr("data-note");
      }
      // Add the Mini
      $target.attr("data-mini", functionName.replace("mini-", ""));
    }
    // Check if we are trying to add/remove a Note
  } else if (/note/.test(functionName)) {
    // Check if we want to remove the Note
    if (functionName === "notes") {
      // Remove the Note
      $target.removeAttr("data-note");
    } else {
      // Check if we already have a Mini
      if ($target.attr("data-mini") !== undefined) {
        // Remove the Mini
        $target.removeAttr("data-mini");
      }
      // Add the Note
      $target.attr("data-note", functionName.replace("note-", ""));
    }
  } else {
    // Check the Function Type
    switch (functionName) {
      case "remove":
        // Close all Contextual menus
        this.closeOpenedContextualMenus();

        // Remove the target
        $target.remove();
        break;
      case "anchor":
        // Check if the target has an Anchor
        if ($target.attr("id") !== undefined) {
          // Remove the Anchor
          $target.removeAttr("id");
        } else {
          // Ask the User to enter the Anchor
          $("#editor-add-anchor input").val("");
          $("#editor-add-anchor").modal("show");

          // Bind Events Listeners
          $("#editor-add-anchor .btn-primary").on("click.wysiwyg", function (e) {
            // Add the Anchor
            $target.attr("id", $(e.currentTarget).parents(".modal").find("input").val().trim());

            // Remove Event Listener
            $("#editor-add-anchor .btn-primary").off("click.wysiwyg");
          });
        }
        break;
      case "marginBottom":
        // Check if the target has mb-0 class
        if ($target.hasClass("mb-0")) {
          // Remove the mb-0 class
          $target.removeClass("mb-0");
        } else {
          // Add the mb-0 class
          $target.addClass("mb-0");
        }

        // Give the Focus to the Episode Content
        this.$content.get(0).focus();
        break;
      case "cutoff":
        // Check if the target has cutoff class
        if ($target.hasClass("cutoff")) {
          // Remove the cutoff class
          $target.removeClass("cutoff");
        } else {
          // Clean any previous cutoff
          this.$content.find(".cutoff").removeClass("cutoff");

          // Add the cutoff class
          $target.addClass("cutoff");
        }

        // Give the Focus to the Episode Content
        this.$content.get(0).focus();
        break;
      case "hideEnding":
        // Check if the target has hide-ending class
        if ($target.hasClass("hide-ending")) {
          // Remove the hide-ending class
          $target.removeClass("hide-ending");
        } else {
          // Add the hide-ending class
          $target.addClass("hide-ending");
        }

        // Give the Focus to the Episode Content
        this.$content.get(0).focus();
        break;
      case "hideDash":
        // Check if the clicked element has hide-dash class
        if ($clicked.hasClass("hide-dash")) {
          // Remove the hide-dash class
          $clicked.removeClass("hide-dash");
        } else {
          // Add the hide-dash class
          $clicked.addClass("hide-dash");
        }

        // Give the Focus to the Episode Content
        this.$content.get(0).focus();
        break;
      case "forceDash":
        // Check if the clicked element has force-dash class
        if ($clicked.hasClass("force-dash")) {
          // Remove the force-dash class
          $clicked.removeClass("force-dash");
        } else {
          // Add the force-dash class
          $clicked.addClass("force-dash");
        }

        // Give the Focus to the Episode Content
        this.$content.get(0).focus();
        break;
      case "showEnding":
        // Check if the target has show-ending class
        if ($target.hasClass("show-ending")) {
          // Remove the show-ending class
          $target.removeClass("show-ending");
        } else {
          // Add the show-ending class
          $target.addClass("show-ending");
        }

        // Give the Focus to the Episode Content
        this.$content.get(0).focus();
        break;
      case "textAlignLeft":
        // Remove all text alignment classes
        $target.removeClass(["text-left", "text-center", "text-right", "text-justify"]);

        // Give the Focus to the Episode Content
        this.$content.get(0).focus();
        break;
      case "textAlignCenter":
        // Center
        $target.removeClass(["text-left", "text-center", "text-right", "text-justify"]);
        $target.addClass("text-center");

        // Give the Focus to the Episode Content
        this.$content.get(0).focus();
        break;
      case "textAlignRight":
        // Align to the right
        $target.removeClass(["text-left", "text-center", "text-right", "text-justify"]);
        $target.addClass("text-right");

        // Give the Focus to the Episode Content
        this.$content.get(0).focus();
        break;
      case "textAlignJustify":
        // Justify
        $target.removeClass(["text-left", "text-center", "text-right", "text-justify"]);
        $target.addClass("text-justify");

        // Give the Focus to the Episode Content
        this.$content.get(0).focus();
        break;
      case "margins":
        // Check if the target has mv-0 class
        if ($target.hasClass("mv-0")) {
          // Remove the mv-0 class
          $target.removeClass("mv-0");
        } else {
          // Add the mv-0 class
          $target.addClass("mv-0");
        }
        break;
      case "legend":
        // Check if the target has hideLegend data
        if ($target.attr("data-hide-legend") === "true") {
          // Set the data-hide-legend attribute to false
          $target.attr("data-hide-legend", "false");
        } else {
          // Set the data-hide-legend attribute to true
          $target.attr("data-hide-legend", "true");
        }
        break;
      case "portraitWhite":
        // Check if the target has portrait data
        if ($target.attr("data-portrait") === "white") {
          // Remove the data-portrait attribute
          $target.removeAttr("data-portrait");
        } else {
          // Set the data-portrait attribute to white
          $target.attr("data-portrait", "white");
        }
        break;
      case "portraitBlack":
        // Check if the target has portrait data
        if ($target.attr("data-portrait") === "black") {
          // Remove the data-portrait attribute
          $target.removeAttr("data-portrait");
        } else {
          // Set the data-portrait attribute to black
          $target.attr("data-portrait", "black");
        }
        break;
      case "autoplay":
        // Check if the target has autoplay data
        if ($target.attr("data-video-autoplay") === "true") {
          // Set the data-video-autoplay attribute to false
          $target.attr("data-video-autoplay", "false");
        } else {
          // Set the data-video-autoplay attribute to true
          $target.attr("data-video-autoplay", "true");
        }
        break;
      case "loop":
        // Check if the target has loop data
        if ($target.attr("data-video-loop") === "true") {
          // Set the data-video-loop attribute to false
          $target.attr("data-video-loop", "false");
        } else {
          // Set the data-video-loop attribute to true
          $target.attr("data-video-loop", "true");
        }
        break;
      case "removeCta":
        // Remove the link
        $clicked.parent().remove();
        break;
      case "updateCta":
        // Open and prefill the modal fields with the selected CTA data
        this.prefillCTAButtonModalFields($clicked.parents("form").find(".editor-insert-cta"), $clicked);
        break;
      case "removeLink":
        // Remove the link
        this.removeLink($clicked);
        break;
      case "updateLink":
        // Open and prefill the modal fields with the selected Link data
        this.prefillLinkModalFields($clicked.parents("form").find(".editor-insert-link"), $clicked);
        break;
      case "insertArticleLink":
        // Open the Modal
        this.saveSelection();
        this.$editor.find(".editor-insert-article-link").modal("show");

        // Clear the Autocomplete field
        this.$editor.find(".editor-insert-article-link .js-autocomplete").val("");
        break;
      default:
        break;
    }
  }

  // Save Content
  this.save();

  // Trigger a "change" event on the Editor
  this.$editor.trigger("change");
};

// Paste Event Listener
Wysiwyg.prototype.pasteEvent = function (e) {
  // Variables
  const event = e.originalEvent || e;
  const style = { B: false, I: false, U: false, S: false, Q: false };
  let text;
  let contents;
  let lastPara;
  let cleaned;
  let pFrag;
  let frag;

  // Get Caret Position
  const pos = this.getCurrentPosition();

  // Get the Main Element of the Destination
  // Detect Style Blocs in Parents
  let dest = $(pos.sel.anchorNode);
  while (!dest.parent().hasClass("wysiwyg-content") && dest.parents(".wysiwyg-content").length > 0) {
    // Get the Parent
    dest = dest.parent();

    // Check if it's a Style Tag
    if (dest.is("b, i, u, s, q")) {
      // Update the Style
      style[dest.prop("tagName")] = true;
    }
  }

  // Check if the Editor is Empty
  if (
    this.$content.children().length === 0 ||
    (this.$content.children().length === 1 && this.$content.children().get(0).tagName === "BR")
  ) {
    // Check if we have HTML Content
    if (event.clipboardData.types.includes("text/html")) {
      // We have HTML Content
      text = event.clipboardData.getData("text/html").replace(/[\r\n]+/g, " ");

      // Clean the Content
      contents = this.cleanPastedHtml(text, style);

      // Paste the Content in the Main Content Editor Node
      this.$content.html(contents);
      return;
    }

    // It's not an HTML Content
    text = event.clipboardData.getData("text/plain").replace(/[\r\n]+/g, "<br />");

    // Add the Text in a new Paragraph
    this.$content.html("<p>" + text.replace(/(\s*<br \/?>\s*){2,}/g, "</p><p>") + "</p>");
    return;
  }

  // Get Pasted Data as HTML Content if the Main Element of the Destination is a Paragraph or a Dialogue Block
  if (dest.is("p, div.dialogue") && event.clipboardData.types.includes("text/html")) {
    // We have HTML Content
    text = event.clipboardData.getData("text/html").replace(/[\r\n]+/g, " ");

    // Wrap the HTML Content into <html><body></body></html>
    if (!/^<html>\s*<body>/.test(text)) {
      text = "<html><body>" + text + "</body></html>";
    }

    // Clean the Content
    contents = this.cleanPastedHtml(text, style);

    // Check if there is several elements to paste
    if (contents.length <= 1) {
      // There is only one element
      // Check if it's a P Tag
      if (contents[0].nodeType === Node.ELEMENT_NODE && contents[0].tagName === "P") {
        // Paste the content of the P Tag
        contents = $(contents[0]).contents();
      }

      // Check if the user want to replace the selection
      if (pos.range && !pos.range.collapsed && pos.range.commonAncestorContainer.nodeType === Node.TEXT_NODE) {
        // Delete the Current Selection
        pos.range.deleteContents();
      }

      // Paste the Content into the Editor Content
      frag = document.createDocumentFragment();
      contents.each(function (idx, el) {
        frag.appendChild(el);
      });
      pos.range.insertNode(frag);
      return;
    }

    // Check if we have P Tag
    if (contents.filter("p").length === 0) {
      // Paste the Content into the Editor Content
      frag = document.createDocumentFragment();
      contents.each(function (idx, el) {
        frag.appendChild(el);
      });
      pos.range.insertNode(frag);
      return;
    }

    // Ensure that each Element is in a P Tag
    cleaned = [];
    lastPara = undefined;
    contents.each(function (idx, el) {
      // Check if it's a P Tag
      if (el.nodeType === Node.ELEMENT_NODE && el.tagName === "P") {
        // Add the Element to the Cleaned Array
        cleaned.push(el);

        // Reset the Last Paragraph Value
        lastPara = undefined;
      } else {
        // Check if we need to create a Paragraph
        if (lastPara === undefined) {
          // Add a new Paragraph to the Cleaned Array
          lastPara = $("<p>");
          cleaned.push(lastPara);
        }

        // Add the Element to the Last Paragraph
        lastPara.append(el);
      }
    });

    // Paste the Cleaned Array in the Main Content Editor Node
    dest.after(cleaned);
    return;
  }

  // It's not an HTML Content. Get the content as a Pain Text
  text = event.clipboardData.getData("text/plain").replace(/[\r\n]+/g, "<br />");

  // Append the Text to a DIV
  const $result = $("<div>" + text + "</div>");

  // Paste the Content into the Editor Content
  contents = $result.contents();
  frag = document.createDocumentFragment();
  contents.each(function (idx, el) {
    frag.appendChild(el);
  });

  // Check if the user want to replace the selection
  if (pos.range && !pos.range.collapsed && pos.range.commonAncestorContainer.nodeType === Node.TEXT_NODE) {
    // Delete the Current Selection
    pos.range.deleteContents();
  }

  // Check if the Common Ancessor is the Root Node
  if ($(pos.range.commonAncestorContainer).hasClass("wysiwyg-content")) {
    // Wrap the New Content in a P Tag
    pFrag = document.createElement("p");
    pFrag.append(frag);

    // Insert the New Content
    pos.range.insertNode(pFrag);
  } else {
    // Insert the New Content
    pos.range.insertNode(frag);
  }
};

// Get the Current Position (Selection and/or Range)
Wysiwyg.prototype.getCurrentPosition = function () {
  let sel, range;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
    }
  } else if (document.selection) {
    range = document.selection.createRange();
  }

  return { sel: sel, range: range };
};

// Save the Current Selection
Wysiwyg.prototype.saveSelection = function () {
  this.selection = this.getCurrentPosition();
};

// Restore any Saved Selection
Wysiwyg.prototype.restoreSelection = function () {
  if (this.selection === undefined) {
    return;
  }
  const sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(this.selection.range);
};

// Insert a Tag at the Cursor Position
Wysiwyg.prototype.insertTagAtCaret = function (tag) {
  // Get Caret Position
  const pos = this.getCurrentPosition();

  // Create the Tag
  const node = document.createElement(tag);

  // Add a Zero-Width Char or the Word "Lien" to Create a Valid Cursor Position inside the Element
  if (tag === "a") {
    $(node).text("lien");
  } else {
    $(node).html("&#x200b;");
  }

  // Insert the Tag at the Cursor Position
  pos.range.insertNode(node);

  // Move the Cursor inside the Created Tag
  moveTheCursorTo(node, 1);

  // Add an Extra Space After the Tag if it's a Link
  if (tag === "a") {
    $(node).after(" ");
  }

  // Return the Inserted Tag
  return $(node);
};

// Insert HTML at the Cursor Position
Wysiwyg.prototype.replaceSelectionByHtml = function (html) {
  // Get Caret Position
  const pos = this.getCurrentPosition();

  // Check if the Common Ancessor is a P Block
  const ancestor = pos.range.commonAncestorContainer;
  if (pos.range.collapsed === false && ancestor.nodeType === Node.ELEMENT_NODE && ancestor.tagName !== "P") {
    // Don't Process the Event
    return;
  }

  // Remove the Selection from the DOM
  pos.range.deleteContents();

  // Range.createContextualFragment() would be useful here but is
  // only relatively recently standardized and is not supported in
  // some browsers (IE9, for one)
  const el = document.createElement("div");
  el.innerHTML = html;
  const frag = document.createDocumentFragment();
  const elChildNodes = [...el.childNodes];
  for (const node of elChildNodes) {
    frag.appendChild(node);
  }
  pos.range.insertNode(frag);

  // Preserve the selection
  if (elChildNodes.length === 0) {
    return;
  }
  moveTheCursorAfter(elChildNodes[0]);
};

// Insert Paragraph at the Cursor Position
Wysiwyg.prototype.insertParagraph = function () {
  // Get the Current Position
  const pos = this.getCurrentPosition();

  // Check if the user has selected something
  if (pos.range === undefined || pos.sel === undefined) {
    return;
  }

  // Check if there is a Selection
  if (pos.range.collapsed !== true) {
    return;
  }

  // There is no Selection, check if we can insert a P Block.
  // We can Insert a P Block only if we are inside a Paragrap (we must split the current one)
  // If we are in the .wysiwyg-content TEXT Node or if we are in a .dialogue
  if (pos.sel.anchorNode.nodeType === Node.TEXT_NODE) {
    // Get the Parent Element
    let parent = $(pos.sel.anchorNode.parentNode);

    // Check if the parent is the .wysiwyg-content
    if (parent.hasClass("wysiwyg-content")) {
      // Insert an Empty P Block
      return this.insertTagAtCaret("p");
    }

    // Check if we are inside a .dialogue
    if (
      parent.parents(".wysiwyg-content > div.dialogue > p").length > 0 ||
      (parent.is("p") && parent.parent().hasClass("dialogue"))
    ) {
      // Get the Correct Parent Node
      parent =
        parent.is("p") && parent.parent().hasClass("dialogue")
          ? parent
          : parent.parents(".wysiwyg-content > div.dialogue > p").eq(0);

      // Append the HTML Content After the Element
      const node = $("<p></p>");
      node.insertAfter(parent);

      // Move the Cursor to the end the created Node
      moveTheCursorToTheEndOf(node.get(0));
      return;
    }

    // Check if we are inside a ul or ol tag
    if (
      parent.parents(".wysiwyg-content > ul > li, .wysiwyg-content > ol > li").length > 0 ||
      (parent.is("li") && parent.parent().is("ul, ol"))
    ) {
      // Get the Correct Parent Node
      parent =
        parent.is("li") && parent.parent().is("ul, ol")
          ? parent
          : parent.parents(".wysiwyg-content > ul > li, .wysiwyg-content > ol > li").eq(0);

      // Append the HTML Content After the Element
      const node = $("<li></li>");
      node.insertAfter(parent);

      // Move the Cursor to the end the created Node
      moveTheCursorToTheEndOf(node.get(0));
      return;
    }

    // Check if the parent is a P Block and if it's parent is the .wysiwyg-content
    let nodeToSplit;
    if (parent.is("p") && parent.parent().hasClass("wysiwyg-content")) {
      // Save the Parent
      nodeToSplit = parent;
    }

    // Check if the parent is a Text-Style Block and if it's parent is a P Block and if it's parent's parent is the .wysiwyg-content
    if (parent.is("b, i, u, s, q") && parent.parents(".wysiwyg-content > p").length > 0) {
      // Save the Parent's Parent
      nodeToSplit = parent.parents(".wysiwyg-content > p").eq(0);
    }

    // Check if we have to Split an Element
    if (nodeToSplit !== undefined) {
      // Clone the Current Range
      const range = pos.range.cloneRange();
      // Move the End of the Range to the End of the Element
      range.setEndAfter(nodeToSplit.get(0));
      // Extract the Range from the DOM
      const lastPartContent = range.extractContents();
      // Add the Extracted Content after the Parent Element
      nodeToSplit.after(lastPartContent);
      // Exit
    }
    // Check if the anchor node is the .wysiwyg-content
  } else if ($(pos.sel.anchorNode).hasClass("wysiwyg-content")) {
    // Insert an Empty P Block
    return this.insertTagAtCaret("p");
  }
};

// Get the right Node to use for links (URL, Note, Mini)
Wysiwyg.prototype.getNodeForLink = function (rangeCommonAncestor) {
  if (rangeCommonAncestor.tagName === "FIGURE" || rangeCommonAncestor.tagName === "A") {
    // Get the Figure or the Link
    return $(rangeCommonAncestor);
  }
  if (rangeCommonAncestor.parentNode.tagName === "A") {
    // Get the Parent Node
    return $(rangeCommonAncestor.parentNode);
  }

  // Wrap the Selection inside the Tag
  return this.wrapInsideTag("a");
};

// Wrap the Current Selection inside a Simple TAG (B, I, U, S, Q)
// If there is no Selection, an Empty DOM Node will be Created
Wysiwyg.prototype.wrapInsideTag = function (tag) {
  // Get the Current Position
  const pos = this.getCurrentPosition();

  // Check if the user has selected something
  if (pos.range === undefined) {
    return;
  }

  // Check if there is a Selection
  if (pos.range.collapsed) {
    // Get the anchor node
    let parent = $(pos.sel.anchorNode.parentNode);

    // Check if the element is inside the editor
    if (!parent.hasClass("wysiwyg-content") && parent.parents(".wysiwyg-content").length === 0) {
      // The cursor is outside the Editor
      return;
    }

    // Check if a parent element has the same tag name
    while (!parent.hasClass("wysiwyg-content")) {
      if (parent.is(tag)) {
        // One of the parent has the same tag name
        // Split the parent at the caret & insert a TextNode
        return splitNodeAtCaret(pos.range, parent.get(0));
      }

      // Take the parent
      parent = parent.parent();
    }

    // We just have to insert a new Node at the caret position
    return this.insertTagAtCaret(tag);
  }

  // There is a selection
  // Check if a parent element has the same tag name
  let parent = $(pos.range.commonAncestorContainer);
  while (!parent.hasClass("wysiwyg-content")) {
    if (parent.is(tag)) {
      // One of the parent has the same tag name
      // Extract the selection from the parent
      return extractSelectionFromNode(pos.range, parent.get(0));
    }

    // Take the parent
    parent = parent.parent();
  }

  // Try to replace all elements with the same tag name in the selection
  for (const el of parent.find(tag)) {
    // Check if the the Element Intersect the Selection
    if (pos.sel.containsNode(el, true)) {
      // Unwrap the node
      const innerNodes = unwrapNode(el);

      // Return the node
      selectNodes(innerNodes);
      parent.normalize();
      return parent;
    }
  }

  // Nothing was replaced
  // Wrap the selection inside the given tag
  const node = document.createElement(tag);
  node.appendChild(pos.range.extractContents());
  pos.range.insertNode(node);

  // Remove empty tags
  removeEmptyNodes(node.parentNode);

  // Return the node
  selectNodeContents(node);
  return $(node);
};

// Wrap the Current Selection inside a Link
// If there is no Selection, an Empty DOM Node will be Created
Wysiwyg.prototype.wrapInsideLink = function (href, targetBlank) {
  // Check if the Current Selection is already a link
  const pos = this.getCurrentPosition();

  // Get the Tag to use as the Link Node
  const tag = this.getNodeForLink(pos.range.commonAncestorContainer);

  // Add href & target attributes if there is a Tag
  if (tag !== undefined) {
    // Remove current mini/note attributes
    removeMiniNoteDataAttr(tag);

    // If the tag is an A
    if (tag.is("a")) {
      // Add an href Attribute
      tag.attr("href", href);
    }

    // Create a target="_blank" attribute if required
    if (targetBlank) {
      tag.attr("target", "_blank");
    } else if (!targetBlank && tag.attr("target") === "_blank") {
      // Or remove the existing one if requested
      tag.removeAttr("target");
    }
  }
};

// Wrap the Current Selection inside a Mini
// If there is no Selection, an Empty DOM Node will be Created
Wysiwyg.prototype.wrapInsideMini = function (dataNumber) {
  // Check if the Current Selection is already a Mini
  const pos = this.getCurrentPosition();

  // Get the Tag to use as the Link Node
  const tag = this.getNodeForLink(pos.range.commonAncestorContainer);

  // Add href & data-mini attributes if there is a Tag
  if (tag !== undefined) {
    // Remove current mini/note attributes
    removeMiniNoteDataAttr(tag);

    // If the tag is an A
    if (tag.is("a")) {
      // Add an href Attribute
      tag.attr("href", `{{ href(episode.minis[${parseInt(dataNumber, 10) - 1}]) }}`);
    }

    // Create the data-mini attribute
    tag.attr("data-mini", dataNumber);
  }
};

// Wrap the Current Selection inside a Note
// If there is no Selection, an Empty DOM Node will be Created
Wysiwyg.prototype.wrapInsideNote = function (dataNumber) {
  // Check if the Current Selection is already a Note
  const pos = this.getCurrentPosition();

  // Get the Tag to use as the Link Node
  const tag = this.getNodeForLink(pos.range.commonAncestorContainer);

  // Add href & data-note attributes if there is a Tag
  if (tag !== undefined) {
    // Remove current mini/note attributes
    removeMiniNoteDataAttr(tag);

    // If the tag is an A
    if (tag.is("a")) {
      // Add an href Attribute
      tag.attr("href", `{{ href(episode.notes[${parseInt(dataNumber, 10) - 1}]) }}`);
    }

    // Create the data-note attribute
    tag.attr("data-note", dataNumber);
  }
};

// Remove the selected Link
Wysiwyg.prototype.removeLink = function ($clicked) {
  // Use the parent element if it's an A HTMLElement
  if ($clicked.parent().is("a")) {
    $clicked = $clicked.parent();
  }

  // Replace the element with it's content
  $clicked.replaceWith($clicked.html());
};

// Prefill the given modal field(s) with a selected Link data
Wysiwyg.prototype.prefillLinkModalFields = function (modal, $clicked) {
  // Show the modal
  modal.modal("show");

  // It’s a link on an Image
  if ($clicked.parents("figure").length > 0) {
    $clicked = $clicked.closest("figure");

    // Move the Cursor to the end the figure
    moveTheCursorToTheEndOf($clicked.get(0));

    // Disable the Href Link button
    modal.find(".nav-link[data-bs-target='#editor-insert-url']").addClass("disabled");
  } else {
    // Use the parent element if it's an A HTMLElement
    if ($clicked.parent().is("a")) {
      $clicked = $clicked.parent();

      // Move the Cursor to the end the link
      moveTheCursorToTheEndOf($clicked.get(0));
    }

    // Enable the Href Link button
    modal.find(".nav-link[data-bs-target='#editor-insert-url']").removeClass("disabled");
  }

  // Save the Selection
  // Warning : We need to save the selection for every link at this precise moment
  this.saveSelection();

  // It’s a Mini, Note or Link
  // Check the type of link to pre-fill some fields
  if ($clicked.attr("data-mini") !== undefined) {
    // Open the right tab
    modal.find(".nav-link[data-bs-target$='-insert-mini']").tab("show");

    // Pre-fill the selected Mini
    modal.find(`.js-editor-insert-mini select option[value=${$clicked.attr("data-mini")}]`).prop("selected", true);
  } else if ($clicked.attr("data-note") !== undefined) {
    const number = parseInt($clicked.attr("data-note"), 10);

    // Open the right tab
    modal.find(".nav-link[data-bs-target$='-insert-note']").tab("show");

    // Pre-fill the selected Note
    modal.find(`.js-editor-insert-note select option[value=${number}]`).prop("selected", true);
  } else {
    // Open the right tab
    modal.find(".nav-link[data-bs-target$='-insert-url']").tab("show");

    // Pre-fill the URL
    modal.find(`.js-editor-insert-url input[type=text]`).val($clicked.attr("href"));

    // Check/Uncheck the Open in new Tab option
    modal.find(".js-editor-insert-url input[type=checkbox]").prop("checked", !!$clicked.attr("target"));
  }
};

// Prefill the given modal field(s) with a selected CTA data
Wysiwyg.prototype.prefillCTAButtonModalFields = function (modal, $clicked) {
  // Show the Modal
  modal.modal("show");

  // Update the donation input
  const $donationInput = modal.find("input[name=cta-donation]");
  $donationInput.prop("checked", $clicked.attr("href") === "https://donorbox.org/les-jours");
  $donationInput.trigger("change.episode.cta", [$clicked.attr("href"), $clicked.attr("target") === "_blank"]);

  // Update the CTA data & the text input
  modal.data("ctaToUpdate", $clicked);
  modal.find("input[name=cta-text]").val($clicked.html());
};

// Insert HTML Block in the Editor Content Container
Wysiwyg.prototype.insertHtmlInEditorContent = function (html) {
  // Get the Current Position
  const pos = this.getCurrentPosition();

  // Check if there is a range element
  if (!pos.range) {
    // We can't process the event
    return;
  }

  // Check if the range is collapsed
  if (pos.range.collapse === false) {
    // Collapse the range to its end
    pos.range.collapse(false);
  }

  // Get the Main Parent
  const parent = $(pos.sel.anchorNode.parentNode);

  // Check if the anchor node is the wysiwyg content
  // or if the parent is the .wysiwyg-content
  if ($(pos.sel.anchorNode).hasClass("wysiwyg-content") || parent.hasClass("wysiwyg-content")) {
    // Insert the Element has a Child Node of the wysiwyg content
    const node = $(html);
    this.$content.append(node);
    return node;
  }

  // Search the Latest Parent in the .wysiwyg-content
  let lastParent = undefined;
  if (parent.parents(".wysiwyg-content").length > 0) {
    lastParent = parent;
    parent.parents().each(function (idx, el) {
      if ($(el).hasClass("wysiwyg-content")) {
        // We have reached the .wysiwyg-content : stop the search
        return false;
      }

      // Save the Element
      lastParent = $(el);
    });
  }
  if (lastParent === undefined) {
    // Don't Process the Event
    return;
  }

  // Append the HTML Content After the Element
  const node = $(html);
  node.insertAfter(lastParent);

  // Move the Cursor to the end the created Node
  moveTheCursorToTheEndOf(node.get(0));

  // Return the Inserted Node
  return node;
};

// Clean HTML Pasted Content
Wysiwyg.prototype.cleanPastedHtml = function (html, style) {
  // Append the Text to a DIV
  const $result = $("<div></div>").append($(html));

  // Clean the HTML Content
  this.cleanDomContent($result, style);

  // Normalize the HTML Content
  $result.get(0).normalize();

  // Clean Empty Text Node
  $result
    .contents()
    .filter(function (idx, el) {
      return el.nodeType === Node.TEXT_NODE && $(el).text().trim().length === 0;
    })
    .each(function (idx, el) {
      $(el).remove();
    });

  // Fix extra stuff in the HTML Code
  html = $result.html();
  // Clean spaces
  html = html.replace(/\s*&nbsp;\s*/g, " ").replace(/\s+/g, " ");
  // Merge Siblings Tags
  html = html.replace(/(<\/b>[\n\r\s]*<b>|<\/i>[\n\r\s]*<i>|<\/u>[\n\r\s]*<u>|<\/s>[\n\r\s]*<s>)/g, " ");
  // Update the HTML
  $result.html(html);

  // Return Cleaned HTML
  return $result.contents().filter(function (idx, el) {
    return el.nodeType !== Node.COMMENT_NODE;
  });
};

// Recursive HTML Cleaning
Wysiwyg.prototype.cleanDomContent = function (dom, parentsTags) {
  // Variables
  const self = this;

  // Iterate through Children
  dom.children().each(function (iEl, el) {
    // Variables
    let $el = $(el);
    let children;
    let newStyle;
    let infered;

    // Check if the span is a wysiwyg-nbsp
    if ($el.is("span.wysiwyg-nbsp")) {
      // Ensure that we have a clean element
      $el.replaceWith('<span class="wysiwyg-nbsp" contenteditable="false">¶</span>');

      // Stop processing the element
      return true;
    }

    // Check if there is a style attribute on the current node
    if ($el.attr("style") !== undefined) {
      // Tag Replacement
      if (
        (el.tagName === "B" && $el.attr("style").match(/font-weight\s*:\s*(normal|400);/)) ||
        (el.tagName === "I" && $el.attr("style").match(/font-style\s*:\s*normal;/)) ||
        ((el.tagName === "U" || el.tagName === "S") && $el.attr("style").match(/text-decoration\s*:\s*none;/))
      ) {
        $el = $('<span style="' + $el.attr("style") + '">' + $el.html() + "</span>");
        $(el).replaceWith($el);
        el = $el.get(0);
      }

      // Infer the Tag from Style Attributes
      if (!infered && $el.attr("style").match(/font-weight\s*:\s*(bold|700|800|900);/)) {
        infered = "b";
        newStyle = $el.attr("style").replace(/font-weight\s*:\s*(bold|700|800|900);/, "");
      }
      if (!infered && $el.attr("style").match(/font-style\s*:\s*italic;/)) {
        infered = "i";
        newStyle = $el.attr("style").replace(/font-style\s*:\s*italic;/, "");
      }
      if (!infered && $el.attr("style").match(/text-decoration\s*:\s*underline;/)) {
        infered = "u";
        newStyle = $el.attr("style").replace(/text-decoration\s*:\s*underline;/, "");
      }
      if (!infered && $el.attr("style").match(/text-decoration\s*:\s*line-through;/)) {
        infered = "s";
        newStyle = $el.attr("style").replace(/text-decoration\s*:\s*line-through;/, "");
      }
      if (infered) {
        $el = $("<" + infered + '><span style="' + newStyle + '">' + $el.html() + "</span></" + infered + ">");
        $(el).replaceWith($el);
        el = $el.get(0);
      }
    }

    // Check if the Tag Match a Parent Tag
    if (parentsTags[el.tagName]) {
      $el = $('<span style="' + $el.attr("style") + '">' + $el.html() + "</span>");
      $(el).replaceWith($el);
      el = $el.get(0);
    }

    // Save the Current Style Tag
    const newTags = $.extend(true, {}, parentsTags);
    if (["B", "I", "Q", "U", "S"].indexOf(el.tagName) !== -1) {
      newTags[el.tagName] = true;
    }

    // Clean Children
    self.cleanDomContent($el, newTags);

    // Check if the Tag is in the Allowed List
    if (["A", "B", "I", "Q", "U", "S"].indexOf(el.tagName) !== -1) {
      // Remove all Tag Attributes
      $.each($.extend(true, [], el.attributes), function (iAttr, attr) {
        // Check if we have to remove the attribute
        if (el.tagName.toLowerCase() === "a" && ["href", "target"].indexOf(attr.nodeName) !== -1) {
          return;
        }
        $el.removeAttr(attr.nodeName);
      });
    } else if (["STYLE", "META", "LINK"].indexOf(el.tagName) !== -1) {
      // Remove Useless Tags
      $el.remove();
    } else if (el.tagName === "BR") {
      // Remove all Tag Attributes
      $.each($.extend(true, [], el.attributes), function (iAttr, attr) {
        $el.removeAttr(attr.nodeName);
      });
    } else if (el.tagName === "P") {
      // Check if the Element has Text
      if ($el.text().trim().length === 0) {
        $el.remove();
      } else {
        // Remove all Tag Attributes
        $.each($.extend(true, [], el.attributes), function (iAttr, attr) {
          $el.removeAttr(attr.nodeName);
        });

        // Remove Leading BR
        children = $el.contents();
        while (children.length > 0 && children[0].tagName === "BR") {
          $(children[0]).remove();
          children = $el.contents();
        }
        // Remove Trailing BR
        children = $el.contents();
        while (children.length > 0 && children[children.length - 1].tagName === "BR") {
          $(children[children.length - 1]).remove();
          children = $el.contents();
        }
      }
    } else {
      // Remove the Current Node from the Tree
      $el.replaceWith($el.html());
    }
  });
};

// Save the HTML Content
Wysiwyg.prototype.save = function () {
  // Check if the cursor is at the end
  if (this.histPos !== this.history.length - 1) {
    // The cursor is not at the end
    // Drop all the content after
    this.history = this.history.slice(0, this.histPos + 1);
  }
  // Check if the cursor is at the beginning
  if (this.histPos === -1) {
    // Create a new history
    this.history = [];
  }

  // Check if we have reach the limit
  if (maxHistory <= this.history.length) {
    // Remove the first element
    this.history = this.history.slice(1, this.history.length);
  }

  // Add a new point in the history
  this.history.push(this.$content.html());
  this.histPos = this.history.length - 1;
};

// Undo the Last Action
Wysiwyg.prototype.undo = function () {
  // Check if there is something to undone
  if (this.histPos <= 0) {
    // There is nothing to undone
    return;
  }

  // Decrease the History Position
  this.histPos--;

  // Replace the Editor Content
  this.$content.html(this.history[this.histPos]);
};

// Redo the Last Undone Action
Wysiwyg.prototype.redo = function () {
  // Check if there is something to redo
  if (this.history.length - 1 <= this.histPos) {
    // There is nothing to redo
    return;
  }

  // Increase the History Position
  this.histPos++;

  // Replace the Editor Content
  this.$content.html(this.history[this.histPos]);
};

// Set the Wysiwyg Content
Wysiwyg.prototype.setContent = function (content) {
  this.$content.html(content);
};

/**
 * Represents an editor
 * @constructor
 * @param {object} userOptions - The default options selected by the user.
 */
$.fn.wysiwyg = function () {
  return new Wysiwyg(this).$editor;
};

/**
 * A function to set the Wysiwyg content
 * @param {string} content
 */
$.fn.setWysiwygContent = function (content) {
  $(this).data(wysiwygEditorDataKey).setContent(content);
};
