import { Toast } from "bootstrap";
import $ from "jquery";

// Add Click Listeners
$("body").on("click", "[data-to-clipboard]", async function (e) {
  // Send the text to the clipboard
  await navigator.clipboard.writeText($(e.currentTarget).data("toClipboard"));

  // Show a small message
  const $ephemeralToast =
    $(`<div class="toast align-items-center text-bg-primary" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="d-flex">
    <div class="toast-body">
      Copié dans le presse-papier&nbsp;!
    </div>
    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
</div>`);
  $("#toast-container").append($ephemeralToast);
  $ephemeralToast.one("hidden.bs.toast", () => {
    Toast.getInstance($ephemeralToast.get(0)).dispose();
    $ephemeralToast.remove();
  });
  Toast.getOrCreateInstance($ephemeralToast.get(0)).show();
});
