import $ from "jquery";

const $body = $("body");

// Listen Click Events on .btn-danger Buttons on Card Elements
$body.on(
  "click.site-page",
  [
    ".site-page #edit-recent-series       .card .btn-danger",
    ".site-page #edit-excluded-obsessions .card .btn-danger",
    ".site-page #edit-free-episodes       .card .btn-danger",
  ].join(", "),
  function (event) {
    // Remove the Card From the DOM
    $(event.currentTarget).closest(".card").parent().remove();
  }
);

// Listen Dropdown events
$body.on(
  "autocomplete.selected.parameters-media",
  [
    ".site-page #edit-recent-series       .js-element  .js-autocomplete",
    ".site-page #edit-excluded-obsessions .js-element  .js-autocomplete",
    ".site-page #edit-free-episodes       .js-element  .js-autocomplete",
  ].join(", "),
  function (event, $selected) {
    // Get the datas
    const endpoint = $selected.data("endpoint");
    const media = $selected.data("id");
    const $deck = $(".site-page [data-parameter-area=" + $(event.target).attr("data-parameter-key") + "]");

    // Create the card
    const $card = $("<div/>", { class: "col" });

    // Get the json
    $.ajax({
      url: endpoint + "/card/" + media,
      method: "GET",
      dataType: "json",
      headers: {
        Accept: "application/json",
      },
    }).done(function (data) {
      // Get the template
      switch (endpoint) {
        case "/obsessions":
        case "/programmes":
          $card.append(
            $.renderTemplate("siteCardsSerie", {
              media: data,
              search: false,
            })
          );
          break;
        case "/episodes":
          $card.append(
            $.renderTemplate("siteCardsEpisode", {
              episode: data,
            })
          );
          break;
      }

      // Add the card to the deck
      $deck.prepend($card);
    });
  }
);

// Listen Save Event
$body.on(
  "click.site-page",
  [
    ".site-page #edit-recent-series       #save-btn",
    ".site-page #edit-excluded-obsessions #save-btn",
    ".site-page #edit-free-episodes       #save-btn",
  ].join(", "),
  function (event) {
    // Prevent default form submission
    event.preventDefault();

    // Get the Form
    const $area = $(`.site-page [data-parameter-area='${$(event.target).attr("data-parameter-key")}']`);
    const $form = $(".site-page form");
    const parameters = {
      key: $(event.target).data("parameter-key"),
      value: [],
    };

    // Select the Parameters Area
    switch ($area.attr("data-parameter-area")) {
      case "recentSeries":
        $area.find(".card").each((idx, element) => {
          parameters.value.push({
            model: $(element).find("input[name='model']").val(),
            document: $(element).find("input[name='document']").val(),
          });
        });
        break;
      case "freeEpisodes":
      case "excludedObsessions":
        $area.find(".card").each((idx, element) => {
          parameters.value.push($(element).find("input[name='document']").val());
        });
    }

    // Send the Home Form
    $.ajaxFormProcessPromise(
      $.ajax({
        method: "PUT",
        url: $form.attr("action"),
        contentType: "application/json; charset=UTF-8",
        data: JSON.stringify(parameters),
      })
    );
  }
);
