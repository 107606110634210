import $ from "jquery";
import throttle from "lodash/throttle";

// ///////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE PLUGIN                                                            //
// ///////////////////////////////////////////////////////////////////////////////////
// Event processing function
async function processInputEvent(event) {
  // Get the Input
  const $input = $(event.currentTarget);

  // Send a jQuery Request
  const json = await $.ajax({
    url: $input.data("validationEndpoint"),
    method: "GET",
    data: { input: $input.val() },
    dataType: "json",
    headers: {
      Accept: "application/json",
    },
  });

  // Get the input's parent
  const $formGrp = $input.parent();

  // Display a Message
  if (json.valid === true) {
    // Validate the Input
    $input.get(0).setCustomValidity("");

    // Remove Field Error Message
    $input.removeClass("is-invalid");
    $formGrp.find(".invalid-feedback").remove();
  } else {
    // Invalidate the Input
    $input.get(0).setCustomValidity(json.message);

    // Display an Error Message
    $input.addClass("is-invalid");
    $formGrp.find(".invalid-feedback").remove(); // Clean previous message
    $formGrp.append('<div class="invalid-feedback">' + json.message + "</div>");
  }
}

// Listen Submit Events on Form Elements
$("body").on(
  "input.inputValidation",
  "form input[data-validation-endpoint]",
  throttle(processInputEvent, 1000, { leading: true, trailing: true })
);
