import $ from "jquery";
import throttle from "lodash/throttle";

// AJAX Search Function
const ajaxSearch = throttle(function ($input) {
  // Get Autocomplete Elements
  const $multiselect = $input.parents(".multiselect");
  const $results = $multiselect.find(".multiselect-results");
  const endpoint = $input.attr("data-endpoint");

  // Trigger the Search
  $.ajax({
    url: endpoint + "/multiselect" + ($input.data("filters") ? "?" + $input.data("filters") : ""),
    method: "GET",
    data: { request: $input.val() },
    dataType: "html",
    headers: {
      Accept: "text/html",
    },
  }).done(function (checkboxes) {
    // Replace the Checkboxes
    $multiselect.data("selectedList", []);
    $results.html(checkboxes);
  });
});

// Process a Key Up Event on the Autocomplete Form
function updateAutocomplete(event) {
  // Get Autocomplete Elements
  const $input = $(event.currentTarget);
  const $multiselect = $input.parents(".multiselect");
  const $results = $multiselect.find(".multiselect-results");

  // Skip Processing if the Length of the Input is Lower than 4
  if ($input.val().length < 3) {
    return;
  }

  // Check if it's a Key Down/Up
  switch (event.which) {
    // Arrow Up
    case 38:
      // Go to the Last Checkbox
      event.preventDefault();
      $results.children().last().trigger("focus");
      return;
    // Arrow Down
    case 40:
      // Go to the First Checkbox
      event.preventDefault();
      $results.children().first().trigger("focus");
      return;
  }

  // Call the AJAX Search Function
  ajaxSearch($input);
}

// Process a Key Up Event on the Multiselect
function handleMultiselectEvent(event) {
  // Get Multiselect Elements
  const $checkbox = $(event.currentTarget);
  const $multiselect = $checkbox.parents(".multiselect");
  let selectedList;
  let media;

  // Check if it's a Key Down/Up
  switch (event.which) {
    // Arrow Up
    case 38:
      // Go to the Previous Sibling
      event.preventDefault();
      $checkbox.prev().trigger("focus");
      return;
    // Arrow Down
    case 40:
      // Go to the Next Sibling
      event.preventDefault();
      $checkbox.next().trigger("focus");
      return;
    // Mouse Click (1)
    case 1:
      // Prevent Default
      event.preventDefault();

      // Get media data
      media = $checkbox.data("media");

      // Get selected elements
      selectedList = $multiselect.data("selectedList");

      // Check if the checkbox was selected
      if ($checkbox.data("selected") === true) {
        // Uncheck the checkbox
        $checkbox.data("selected", false);
        $checkbox.find(".badge").html("");

        // Update the selected list
        selectedList = selectedList.filter(function (el) {
          return el !== media.href;
        });
      } else {
        // Check the checkbox
        $checkbox.data("selected", true);

        // Update the selected list
        selectedList.push(media.href);
      }

      // Save the new list
      $multiselect.data("selectedList", selectedList);

      // Update badges
      selectedList.forEach(function (el, idx) {
        $("#mselect-" + el)
          .find(".badge")
          .html(idx + 1);
      });
  }
}

// Bind Events Listeners on the Body
$("body")
  .on("keyup.multiselect", ".multiselect .multiselect-searchbox", updateAutocomplete)
  .on(
    "keyup.multiselect, click.multiselect",
    ".multiselect .multiselect-results > .multiselect-checkbox",
    handleMultiselectEvent
  );
