import $ from "jquery";
import throttle from "lodash/throttle";

const $body = $("body");

// Listen Save Events to Submit the Form
$body.on("wysiwyg.save", ".podcasts-page #article .wysiwyg-editor", function (event, editorContent) {
  const $editor = $(this);
  const $form = $editor.parents("form");
  const $input = $form.find("input[name=editorContent]");
  const $submit = $form.find("button[type=submit]");

  // Attach an Event to initialize the Wysiwyg Editor
  if ($form.data("ajaxFormEventAttached") === undefined) {
    $form.on("ajaxFormProcessed", function (event, data) {
      // Replace the Editor content
      $editor.setWysiwygContent(data.editorCnt);
    });
    $form.data("ajaxFormEventAttached", true);
  }

  // Add the Editor Content into the Input Field & Submit the Form
  $input.val(editorContent);
  $submit.trigger("click");
});

// Listen Update Header Autocomplete Selection Events
$body.on(
  "autocomplete.selected.podcast",
  ".podcasts-page #update-header .js-autocomplete",
  function (event, $selected) {
    // Get Selected Element Information
    const id = $selected.data("id");
    const endpoint = $selected.data("endpoint");

    // Get the Card
    $.ajax({
      url: endpoint + "/figure/" + id,
      method: "GET",
      dataType: "html",
      headers: {
        Accept: "text/html",
      },
    }).done(function (card) {
      // Append the Card to the List
      $selected.parents(".modal-body").find(".js-selected-content").html(card);
    });
  }
);

// Listen if the User has Chose an Header
$body.on("click.podcast", ".podcasts-page #update-header .js-update-header", function () {
  // Show the Preview
  $(".podcasts-page .js-media-image-holder").html($(".podcasts-page #update-header .js-selected-content").children());
});

// Listen if the User drop the Header
$body.on("click.podcast", ".podcasts-page .js-remove-header", function () {
  // Remove the Header
  $(".podcasts-page .js-media-image-holder").children().remove();
});

// Listen Programme Autocomplete Selection Events
$body.on("autocomplete.selected.podcast", ".podcasts-page #add-podcast .js-autocomplete", function (event, $selected) {
  // Get Selected Element Information
  const href = $selected.data("href");
  const endpoint = $selected.data("endpoint");
  const id = $selected.data("id");
  const $dropdown = $selected.parents(".dropdown");
  const $input = $dropdown.find(".js-autocomplete");

  // Replace the Input Content by the Programme href
  $input.val(href);

  // Get the last created podcast href
  $.ajax({
    url: `${endpoint}/${id}/prepare-next-podcast?select=href`,
    method: "GET",
    dataType: "json",
    headers: {
      Accept: "application/json",
    },
  }).done(function (data) {
    $(".podcasts-page .js-previous-href .text-success").text(data.last?.href || "Aucun podcast précédent");

    if (data.subtitle && data.subtitle !== "") {
      // Update the subtitle and short inputs
      $("input[name=subtitle], input[name=short]").each((idx, input) => {
        $(input).closest(".edith-editor").setEdithEditorContent(data.subtitle);
        $(input).siblings(".edith-element").trigger("keyup");
      });
    }
  });
});

// Listen Add Author/ Update Editor Autocomplete Selection Events
$body.on("change.podcast", ".podcasts-page #add-author .js-select", function (event) {
  // Get Selected Element Information
  const $selected = $(event.currentTarget).find("option:selected");

  // Check if the Selected Option is "Other"
  if ($selected.val() === "other") {
    // Show the Other Field
    $selected.parents(".modal-body").find(".js-add-author-other").removeClass("d-none");
  } else {
    // Hide & Clean the Other Field
    $selected.parents(".modal-body").find("input[name=other]").val("");
    $selected.parents(".modal-body").find(".js-add-author-other").addClass("d-none");
  }
});
// Listen Add Author/ Update Editor Autocomplete Selection Events
$body.on("autocomplete.selected.podcast", ".podcasts-page #add-author .js-autocomplete", function (event, $selected) {
  // Get Selected Element Information
  const id = $selected.data("id");
  const text = $selected.data("text");
  const $dropdown = $selected.parents(".dropdown");
  const $input = $dropdown.find(".js-autocomplete");

  // Update the Autocomplete
  $input.data("author", id);
  $input.data("text", text);
  $input.val(text);
});
// Listen Keyup Events on the Author/Editor Autocomplete Input
$body.on("change.podcast", ".podcasts-page #add-author .js-autocomplete", function (event) {
  // Get the jQuery Input
  const $input = $(event.currentTarget);

  // Check if the Value has Change
  if ($input.val() !== $input.data("text")) {
    // Reset data-attributes
    $input.removeData("author");
    $input.removeData("text");
  }
});
// Update the Author/Editor Value before Any Submit
$body.on("click.podcast", ".podcasts-page #add-author form .btn-primary", function (event) {
  // Get the jQuery Input
  const $clicked = $(event.currentTarget);
  const $form = $clicked.parents("form");
  const $author = $form.find("input[name=author], input[name=editor]");

  // Check if the Input has a data-author attribute
  if ($author.data("author")) {
    // Replace the Value by it's data-author attribute
    $author.val($author.data("author"));
  }

  // Submit the Form
  $form.get(0).submit();
});

// Listen if the User pick a Tag in the Dropdown
$body.on("click.podcast", ".podcasts-page .js-add-tags .dropdown-item", function (event) {
  const $button = $(event.currentTarget);
  const $form = $button.parents("form");
  const $tags = $form.find(".js-tags-list");

  // Add a new Tag
  $("<span>", { class: "badge rounded-pill bg-primary p-2 me-2 mb-2" })
    .append($("<input>", { type: "hidden", name: "tags", value: $button.data("word") }))
    .append($button.data("word"))
    .append($("<span>", { class: "fa-solid fa-xmark pointer ms-1" }))
    .appendTo($tags);

  // Clear the Dropdown
  $form.find(".js-add-tags .js-autocomplete").val("");
});

// Listen if the User remove a Tag in the List
$body.on("click.podcast", ".podcasts-page .js-tags-list .badge", function (event) {
  // Remove the Tag
  $(event.currentTarget).remove();
});

// Listen if the User want to remove all Tags
$body.on("click.podcast", ".podcasts-page .js-tags-list .js-delete-all-tags", function (event) {
  // Remove the Tag
  $(event.currentTarget).parents(".js-tags-list").find(".badge").remove();
});

// Listen if the User want to Create a New Tag
$body.on("click.podcast", ".podcasts-page .js-add-tags .js-create-new-tag", function (event) {
  const $button = $(event.currentTarget);
  const $form = $button.parents("form");
  const $tags = $form.find(".js-tags-list");
  const $dropdown = $button.parents(".dropdown");
  const $input = $dropdown.find(".js-autocomplete");

  // Create a New Tag
  $.ajax({
    url: "/tags",
    method: "POST",
    data: {
      word: $input.val(),
    },
    dataType: "json",
    headers: {
      Accept: "application/json",
    },
  })
    .done(function (tag) {
      // Add a new Tag
      $("<span>", { class: "badge rounded-pill bg-primary p-2 me-2 mb-2" })
        .append($("<input>", { type: "hidden", name: "tags", value: tag.word }))
        .append(tag.word)
        .append($("<span>", { class: "fa-solid fa-xmark pointer ms-1" }))
        .appendTo($tags);

      // Clear the Dropdown
      $form.find(".js-add-tags .js-autocomplete").val("");
    })
    .fail(function () {
      // Clear the Dropdown
      $form.find(".js-add-tags .js-autocomplete").val("");
    });
});

// Listen if the User pick a Category in the Dropdown
$body.on("click.podcast", ".podcasts-page .js-add-categories .dropdown-item", function (event) {
  const $button = $(event.currentTarget);
  const $form = $button.parents("form");
  const $categories = $form.find(".js-categories-list");

  // Add a new Tag
  $("<span>", { class: "badge rounded-pill bg-primary p-2 me-2 mb-2" })
    .append($("<input>", { type: "hidden", name: "categories", value: $button.data("code") }))
    .append($button.data("name"))
    .append($("<span>", { class: "fa-solid fa-xmark pointer ms-1" }))
    .appendTo($categories);

  // Clear the Dropdown
  $form.find(".js-add-tags .js-autocomplete").val("");
});

// Listen if the User remove a Category in the List
$body.on("click.podcast", ".podcasts-page .js-categories-list .badge", function (event) {
  // Remove the Tag
  $(event.currentTarget).remove();
});

// Listen Clicks on Remove Podcast File Buttons
$(".podcasts-page #podcast .js-remove-podcast-file").on("click.podcast", function (event) {
  // Remove the Button's Parent
  $(event.currentTarget).parents(".card").remove();
});

// Create the Editor
$([".podcasts-page #article .wysiwyg-editor"].join(",")).each(function () {
  $(this).wysiwyg();
});

// Listen clicks on fcmToken button
$("#test-push").on("click.podcast", "[data-fill-fcmtoken]", function (event) {
  const $button = $(event.currentTarget);
  const $form = $button.parents("form");

  $form.find("[name=fcmToken]").val($button.data("fillFcmtoken"));
});

// Test if the Google Showcase field is valid
function validateGoogleShowcaseField() {
  const $subtitleEdith = $(".podcasts-page #podcast .edith-editor:has(input[name=subtitle])");
  const $sentencesEdith = $(".podcasts-page #podcast .edith-editor:has(input[name=sentences])");

  let sentencesText = $sentencesEdith.getEdithEditorContent();
  if (sentencesText === "") {
    sentencesText = $subtitleEdith.getEdithEditorContent();
  }
  if ($sentencesEdith.data("isScoop") === true) {
    sentencesText = `<b>Info «&nbsp;Les&nbsp;Jours&nbsp;».</b> ${sentencesText}`;
  } else if ($sentencesEdith.data("isNewSerie") === true) {
    sentencesText = `<b>Nouvelle série.</b> ${sentencesText}`;
  } else if ($sentencesEdith.data("isNewSeason") === true) {
    sentencesText = `<b>Nouvelle saison.</b> ${sentencesText}`;
  }
  sentencesText = sentencesText.replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>|<!--[\s\S]*?-->/gi, "");

  const groups = (sentencesText.match(/[^.!?:]+(?:[.!?:]|$)/gm) ?? [])
    .map((el) => el.trim())
    .filter((el) => el?.length > 0);
  if (groups.length < 2 || 3 < groups.length) {
    $sentencesEdith.find(".alert").remove();
    $sentencesEdith.append(`
        <div class="alert alert-warning mt-1" role="alert">
          Aucun sous-titre valide pour Google Showcase 😢
        </div>
      `);
  } else {
    $sentencesEdith.find(".alert").remove();
    $sentencesEdith.append(`
        <div class="alert alert-light mt-1" role="alert">
          Aperçu&nbsp:<br />
          <ul class="m-0">
            <li>${groups.join("</li><li>")}</li>
          </ul>
        </div>
      `);
  }
}
if ($(".podcasts-page #podcast .edith-editor:has(input[name=sentences])").length > 0) {
  $body.on(
    "input.podcast.sentences",
    ".podcasts-page #podcast .edith-editor:has(input[name=subtitle]), .podcasts-page #podcast .edith-editor:has(input[name=sentences])",
    throttle(validateGoogleShowcaseField, 1000, { leading: true, trailing: true })
  );
  $body.on(`ajaxFormProcessed.podcast.sentences`, validateGoogleShowcaseField);
  validateGoogleShowcaseField();
}
