import Dropzone from "dropzone";
import $ from "jquery";

const eventsNamespace = "lesjours.dropzone";

function getMetadataCredit(metadata) {
  return metadata.iptc?.Credit || metadata.iptc?.CopyrightNotice;
}

function initializeDropzone(dzNode) {
  // Skip initialization if the dropzone is already initialized
  const $dzEl = $(dzNode);
  if ($dzEl.data("dropzoneInitialized") === true) {
    return;
  }
  $dzEl.data("dropzoneInitialized", true);

  // Initialize the dropzone
  const $formEl = $dzEl.parents("form").eq(0);
  const $clickableEl = $dzEl.find(".js-dropzone-clickable").eq(0);
  const formType = $dzEl.data("dropzoneField");
  const $previewEl = $formEl.find("[data-dropzone-preview-for-field=" + formType + "]").eq(0);

  return new Dropzone(dzNode, {
    url: "/dz-upload",
    method: "post",
    timeout: 0,
    parallelUploads: 1,
    maxFilesize: 256,
    clickable: $clickableEl.get(0),
    previewsContainer: $previewEl.get(0),
    previewTemplate: $dzEl.find("template").get(0).innerHTML,
    maxThumbnailFilesize: 20,
    thumbnailWidth: null,
    thumbnailHeight: null,
    accept: function (file, done) {
      // Self Pointer
      const self = this;

      // Check if we are limited to a single file
      if ($(this.element).data("dropzoneMultiple") === false) {
        // Remove all Temporary Files
        $.each(self.files, function (idx, uploadedFile) {
          // Don't remove the current file
          if (file === uploadedFile) {
            return;
          }

          // It's a previous file : remove it
          self.removeFile(uploadedFile);
        });

        // Clean Previous Files (Getting from the CMS)
        $(this.previewsContainer)
          .find(".card")
          .each(function (idx, el) {
            // Don't remove the current file
            if (file.previewElement === el) {
              return;
            }

            // It's a previous file : remove it
            $(el).remove();
          });
      }

      // Accept the File
      done();
    },
    init: function () {
      // Self Pointer
      const self = this;

      // File Sending Listener : function(file, xhr, formData)
      this.on("sending", function (file, xhr, formData) {
        // Get the File Preview Element
        const $previewEl = $(file.previewElement);

        // Add the field to formData
        formData.append("field", formType);

        // Show the Upload Progress
        $previewEl.find(".dz-progress, .dz-remove").removeClass("d-none");
        $previewEl.find(".dz-refresh").addClass("d-none");

        // Bind Clicks Listeners on Buttons
        $previewEl.on("click", ".dz-remove", function () {
          self.removeFile(file);
        });
        $previewEl.on("click", ".dz-refresh", function () {
          self.uploadFile(file);
        });

        // Add the File Preview by Ourself
        if ($previewEl.find("[data-dz-preview]").length > 0) {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              // Get the Preview Element
              const $preview = $previewEl.find("[data-dz-preview]");

              // Check if it's a Player or a Link
              if ($preview.get(0).tagName === "A") {
                $preview.attr("href", reader.result);
              } else {
                $preview.attr("src", reader.result);
              }
            },
            false
          );
          reader.readAsDataURL(file);
        }
      });
      // Upload Progress Listener : function(file, progress, bytesSent)
      this.on("uploadprogress", function (file, progress) {
        // Get the File Preview Element
        const $previewEl = $(file.previewElement);

        // Update the Progress Bar
        $previewEl.find(".dz-progress").attr("value", progress);
      });
      // Success Listener : function(file, response)
      this.on("success", function (file, response) {
        // Get the File Preview Element
        const $previewEl = $(file.previewElement);
        const $pane = $previewEl.parents(".tab-pane");
        const jsonResponse = JSON.parse(response);
        const resized = jsonResponse.resized;
        if (resized !== undefined) {
          delete jsonResponse.resized;
        }
        const metadata = jsonResponse.metadata;
        if (metadata !== undefined) {
          delete jsonResponse.metadata;
        }

        // Update the Preview Element
        $previewEl.find(".dz-progress").addClass("d-none");
        $previewEl.find(".dz-details, .dz-success-mark").removeClass("d-none");
        $previewEl.find("[data-dz-file]").val(JSON.stringify(jsonResponse));

        // Show resize information
        if (resized !== undefined) {
          $previewEl.find("[data-dz-resized]").html($.renderTemplate("partialsDropzoneResized", { resized: resized }));
        }

        // Show metadata information
        if (metadata !== undefined) {
          if (metadata.danger) {
            $previewEl
              .find("[data-dz-danger]")
              .html($.renderTemplate("partialsDropzoneDanger", { metadata: metadata }));
          }
          if (metadata.warning) {
            $previewEl
              .find("[data-dz-warning]")
              .html($.renderTemplate("partialsDropzoneWarning", { metadata: metadata }));
          }
          if (metadata.elements) {
            $previewEl
              .find("[data-dz-metadata]")
              .html($.renderTemplate("partialsDropzoneMetadata", { metadata: metadata }));
          }
        }

        // Update Filename Display
        $previewEl.find("[data-dz-name]").html(jsonResponse.name);

        // Initialize Edith
        if ($previewEl.find(".edith-editor").length > 0) {
          $previewEl.find(".edith-editor").initializeEdithEditor();
        }

        // Pre-fill HREFs
        if ($pane.data("preFillHref")) {
          const preFillHref = $pane.data("preFillHref");
          $pane.find("input[name=href]").each((idx, href) => {
            $(href).val(preFillHref + (idx + 1));
          });
        }

        // Pre-fill credits
        if (metadata !== undefined && $(this.element).data("dropzonePreFillCredit") === true) {
          const credit = getMetadataCredit(metadata.elements);
          if (credit) {
            // Get the credits field
            const $area = $previewEl.find("[name=credit]").length === 0 ? $pane : $previewEl;
            const $areaEditor = $area.find("input[name=credit]").closest(".edith-editor");

            // Check if there is already something in the editor
            if ($areaEditor.getEdithEditorContent() === "") {
              // Set the credits
              $area.find("input[name=credit]").closest(".edith-editor").setEdithEditorContent(credit);
            }
          }
        }
      });
      // Error Listener : function(file, errorMessage, xhr)
      this.on("error", function (file) {
        // Get the File Preview Element
        const $previewEl = $(file.previewElement);

        // Display the Error Message
        $previewEl.find(".dz-progress").addClass("d-none");
        $previewEl.find(".dz-error-message, .dz-error-mark").removeClass("d-none");
        $previewEl.find(".dz-refresh").removeClass("d-none");
      });
      // File Removed Listener : function(file)
      this.on("removedfile", function (file) {
        // Check if there is an error with the file
        if (file.status === "error") {
          // Nothing to do
          return;
        }

        // Delete the File
        $.ajax({
          url: "/dz-upload",
          method: "DELETE",
          data: { file: JSON.parse($(file.previewElement).find("[data-dz-file]").val()).name },
        });
      });
    },
  });
}

// ///////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE PLUGIN                                                            //
// ///////////////////////////////////////////////////////////////////////////////////
// JQuery function
$.fn.dropzone = function () {
  return this.each((idx, el) => initializeDropzone(el));
};

// Listen ajaxFormProcessed Events
$("body").on(`ajaxFormProcessed.${eventsNamespace}`, function (event) {
  // Initialize Dropzone Elements
  $(event.target)
    .find(".js-dropzone")
    .each((idx, el) => initializeDropzone(el));
});

// Initialize Dropzone Elements
$(".js-dropzone").each((idx, el) => initializeDropzone(el));
