import $ from "jquery";
import throttle from "lodash/throttle";

const $body = $("body");

// Delete a Live Post
function deletePost($button) {
  $.ajax({
    url: $button.data("actionUrl"),
    method: "DELETE",
  }).done(function () {
    // Reload the Page
    window.location.reload(true);
  });
}

// Publish/Unpublish a Live Post
function publishOrUnpublishPost($button) {
  $.ajaxFormProcessPromise(
    $.ajax({
      url: $button.data("actionUrl"),
      method: "PUT",
    }),
    function (success) {
      // Check if it's a success
      if (!success) {
        // Stop processing the event
        return;
      }

      // Create the Editor & the Datetime Picker
      $(".episodes-page #article .js-datetime-picker").initializeDatetimePicker();
      $(".episodes-page #article .wysiwyg-editor").wysiwyg();
    }
  );
}

// A throttled function to warn of unsaved changes
const warnOfUnsavedChanges = throttle((event) => {
  const $submit = $(event.currentTarget).closest("form").find("input[type=submit]");

  // Warn that there are unsaved changes
  if ($submit.hasClass("btn-primary")) {
    $submit.removeClass("btn-primary");
    $submit.addClass("btn-danger");
  }
}, 2000);

// Listen Save Events to Submit the Form
$body.on(
  "wysiwyg.save",
  [
    ".episodes-page #article .wysiwyg-editor",
    ".episodes-page #a-side  .wysiwyg-editor",
    ".episodes-page #b-side  .wysiwyg-editor",
  ].join(","),
  function (event, editorContent) {
    const $editor = $(this);
    const $form = $editor.parents("form");
    const $input = $form.find("input[name=editorContent], input[name=aSideEditorCnt], input[name=bSideEditorCnt]");
    const $submit = $form.find("button[type=submit]");

    // Attach an Event to initialize the Wysiwyg Editor
    if ($form.data("ajaxFormEventAttached") === undefined) {
      $form.on("ajaxFormProcessed", function (event, data) {
        switch ($form.attr("data-ajax-form-key")) {
          case "episode-form-article":
            $("form[data-ajax-form-key='episode-form-slideshow']").replaceWith(
              $.renderTemplate("episodesFormsSlideshow", data)
            );
            break;
          case "live-episode-post":
            $("[data-ajax-form-key='live-post-menu']").replaceWith(
              $.renderTemplate("episodesEditorLivePostMenu", data)
            );
            $("form[data-ajax-form-key='episode-form-slideshow']").replaceWith(
              $.renderTemplate("episodesFormsSlideshow", data)
            );
            break;
        }

        // Replace the Minis/Notes list on the Board and Select
        $(`.card-deck-wrapper[data-ajax-form-key="episode-form-minis-cards"]`).replaceWith(
          $.renderTemplate("episodesFormsMinisCards", data)
        );
        $(`.card-deck-wrapper[data-ajax-form-key="episode-form-notes-cards"]`).replaceWith(
          $.renderTemplate("episodesFormsNotesCards", data)
        );

        // Replace the Editor content
        $editor.setWysiwygContent(data.editorCnt);
      });
      $form.data("ajaxFormEventAttached", true);
    }

    // Add the Editor Content into the Input Field & Submit the Form
    $input.val(editorContent);
    $submit.trigger("click");
  }
);

// Prevent default events if the Enter key is pressed to avoid form submission
$body.on(
  "keypress.episodes",
  [".episodes-page #article input", ".episodes-page #a-side  input", ".episodes-page #b-side  input"].join(","),
  function (event) {
    if (event.which === 13) {
      // Enter (13)
      event.preventDefault();
    }
  }
);

// Listen Update Header Autocomplete Selection Events
$body.on(
  "autocomplete.selected.episode",
  ".episodes-page #update-header .js-autocomplete",
  function (event, $selected) {
    // Get Selected Element Information
    const id = $selected.data("id");
    const endpoint = $selected.data("endpoint");

    // Get the Card
    $.ajax({
      url: endpoint + "/figure/" + id,
      method: "GET",
      dataType: "html",
      headers: {
        Accept: "text/html",
      },
    }).done(function (card) {
      // Append the Card to the List
      $selected.parents(".modal-body").find(".js-selected-content").html(card);
    });
  }
);

// Listen if the User has Chose an Header
$body.on("click.episode", ".episodes-page #update-header .js-update-header", function () {
  // Show the Preview
  $(".episodes-page .js-media-image-holder").html($(".episodes-page #update-header .js-selected-content").children());
});

// Listen if the User drop the Header
$body.on("click.episode", ".episodes-page .js-remove-header", function () {
  // Remove the Header
  $(".episodes-page .js-media-image-holder").children().remove();
});

// Listen Update Video Autocomplete Selection Events
$body.on("autocomplete.selected.episode", ".episodes-page #update-video .js-autocomplete", function (event, $selected) {
  // Get Selected Element Information
  const id = $selected.data("id");
  const endpoint = $selected.data("endpoint");

  // Get the Card
  $.ajax({
    url: endpoint + "/figure/" + id,
    method: "GET",
    dataType: "html",
    headers: {
      Accept: "text/html",
    },
  }).done(function (card) {
    // Append the Card to the List
    $selected.parents(".modal-body").find(".js-selected-content").html(card);
  });
});

// Listen if the User has Choose a Video
$body.on("click.episode", ".episodes-page #update-video .js-update-video", function () {
  // Show the Preview
  $(".episodes-page .js-media-video-holder").html($(".episodes-page #update-video .js-selected-content").children());
});

// Listen if the User Drop the Video
$body.on("click.episode", ".episodes-page .js-remove-video", function () {
  // Remove the Video
  $(".episodes-page .js-media-video-holder").children().remove();
});

// Listen if the User try to Remove the Home Image or the Home Mobile
$body.on("click.episode", ".episodes-page .js-remove-home-element", function (event) {
  const $button = $(event.currentTarget);

  // Remove the preview and file’s input
  $button.parents(`.js-${$button.data("elementType")}-preview`).remove();
});

// Listen if the User change the Episode Type
$body.on("change.episode", ".episodes-page .js-change-episode-type", function (event) {
  const $changed = $(event.currentTarget);
  const $form = $changed.parents("form");
  const $header = $form.find(".js-episode-with-image");
  const $quote = $form.find(".js-episode-without-image");
  const selected = $changed.val();

  // Check if we switch from an Episode with Image to an Episode without Image and if the Quote Form is Hidden
  if (selected === "sans-image" && !$quote.is(":visible")) {
    // Show the Quote Form & Clear the Header Form
    $quote.removeClass("d-none");
    $header.addClass("d-none");
    $header.find(".js-media-image-holder").children().remove();
  }

  // Check if we switch from an Episode without Image to an Episode with Image and if the Header Form is Hidden
  if (selected !== "sans-image" && !$header.is(":visible")) {
    // Show the Header Form & Clear the Quote Form
    $header.removeClass("d-none");
    $quote.addClass("d-none");
    $quote.find("input[name=quote]").removeAttr("value").val("");
  }
});

// Listen Clicks on Mini/Note Copy Button
$body.on("click.episode", ".episodes-page #asides .js-copy-field", function (event) {
  const $clicked = $(event.currentTarget);
  const $modal = $(".episodes-page #copy-field");
  const field = $clicked.data("copy-field");
  const key = $clicked.data("copy-key");

  // Copy input fields into the Modal
  $modal.find("input[name=field]").val(field);
  $modal.find("input[name=key]").val(key);

  // Show the Modal
  $modal.modal("show");
});

// Listen Mini/Note Copy Autocomplete Selection Events
$body.on("autocomplete.selected.episode", ".episodes-page #copy-field .js-autocomplete", function (event, $selected) {
  // Get Selected Element Information
  const id = $selected.data("id");
  const endpoint = $selected.data("endpoint");

  // Get the Card
  $.ajax({
    url: endpoint + "/figure/" + id,
    method: "GET",
    dataType: "html",
    headers: {
      Accept: "text/html",
    },
  }).done(function (card) {
    // Append the Card to the List
    $selected.parents(".modal-body").find(".js-selected-content").html(card);
  });
});

// Listen Copy Mini/Note Autocomplete Selection Events
$body.on("autocomplete.selected.episode", ".episodes-page #asides .js-autocomplete", function (event, $selected) {
  // Get Selected Element Information
  const key = $selected.data("copy-key");
  const field = $selected.data("copy-field");
  const episode = $selected.data("copy-episode");

  // Copy the Selected Note/Mini
  $.ajax({
    url: "/episodes/" + episode + "/copy-field",
    method: "POST",
    data: {
      episode: $selected.parents(".dropdown").data("episode"),
      field: field,
      key: key,
    },
  }).done(function () {
    // Reload the Page on the Correct Tab
    window.location.href =
      window.location.href.replace(/#[a-zA-Z0-9-]+$/, "") + "#" + $selected.parents(".tab-pane").attr("id");
    window.location.reload(true);
  });
});

// Listen on input and Edith editors keypressed event on Minis/Notes board
$body.on(
  "input.episode",
  ".episodes-page #asides input, .episodes-page #asides .edith-visual, .episodes-page #asides .edith-code",
  warnOfUnsavedChanges
);

// Listen Add Mini Modal switch Media type
$body.on("change.episode", ".episodes-page #add-mini select.js-media-type", function (event) {
  // Get the Selected Element
  const $selected = $(event.currentTarget);
  const $modal = $selected.parents(".modal");
  const $autocomplete = $modal.find(".js-autocomplete");

  // Clean autocomplete data
  $autocomplete.removeAttr("data-filters");
  $autocomplete.removeData("endpoint");

  // Clean Previous Elements
  $modal.find(".js-autocomplete, .js-select").parent().addClass("d-none");
  $modal.find(".js-autocomplete, .js-select, input[name=model], input[name=doc]").val("");
  $modal.find(".js-select, .js-selected-content").html("");

  // Check if the User has Select a Media Type
  if ($selected.val() === "") {
    // Stop Event Processing
    return;
  }

  switch ($selected.val()) {
    case "image":
      // Update the Autocomplete Endpoint
      $autocomplete.attr("data-endpoint", "/medias/" + $selected.val());
      $autocomplete.attr("data-filters", `episode=${$selected.data("episode")}`);

      // Show Autocomplete Form
      $autocomplete.parent().removeClass("d-none");
      break;
    case "article":
    case "serie":
      // Update the Autocomplete Endpoint
      $selected.val() === "article"
        ? $autocomplete.attr("data-endpoint", '["/episodes","/podcasts"]')
        : $autocomplete.attr("data-endpoint", '["/obsessions","/programmes"]');

      // Show Autocomplete Form
      $autocomplete.parent().removeClass("d-none");
      break;
    default:
      // Get Options for the Select Element
      $.ajax({
        url: "/medias/" + $selected.val() + "/episode/" + $selected.data("episode"),
        method: "GET",
        dataType: "html",
        headers: {
          Accept: "text/html",
        },
        data: {
          sortBy: { title: 1 },
        },
      }).done(function (options) {
        // Add Options to the Select Element
        $modal.find(".js-select").html(options);

        // Show Select Form
        $modal.find(".js-select").parent().removeClass("d-none");
      });
  }
});

// Listen Add Mini Autocomplete Selection Events
$body.on("autocomplete.selected.episode", ".episodes-page #add-mini .js-autocomplete", function (event, $selected) {
  // Get Selected Element Information
  const id = $selected.data("id");
  const model = $selected.data("model");
  const endpoint = $selected.data("endpoint");

  // Update Inputs
  $selected.parents(".modal-body").find("input[name=model]").val(model);
  $selected.parents(".modal-body").find("input[name=doc]").val(id);

  // Get the Card
  $.ajax({
    url: endpoint + "/figure/" + id,
    method: "GET",
    dataType: "html",
    headers: {
      Accept: "text/html",
    },
  }).done(function (card) {
    // Append the Card to the List
    $selected.parents(".modal-body").find(".js-selected-content").html(card);
  });
});

// Listen Add Mini Select Change Events
$body.on("change.episode", ".episodes-page #add-mini .js-select", function (event) {
  // Get Selected Element Information
  const $selected = $(event.currentTarget).find("option:selected");
  const id = $selected.data("id");
  const model = $selected.data("model");
  const endpoint = $selected.data("endpoint");

  // Update Inputs
  $selected.parents(".modal-body").find("input[name=model]").val(model);
  $selected.parents(".modal-body").find("input[name=doc]").val(id);

  // Get the Card
  $.ajax({
    url: endpoint + "/figure/" + id,
    method: "GET",
    dataType: "html",
    headers: {
      Accept: "text/html",
    },
  }).done(function (card) {
    // Append the Card to the List
    $selected.parents(".modal-body").find(".js-selected-content").html(card);
  });
});

// Listen Obsession Autocomplete Selection Events
$body.on(
  "autocomplete.selected.episode",
  [
    ".episodes-page #add-episode .js-autocomplete",
    ".episodes-page #add-infography .js-autocomplete",
    ".episodes-page #add-story .js-autocomplete",
    ".episodes-page #add-asidebside .js-autocomplete",
    ".episodes-page #add-live .js-autocomplete",
  ].join(", "),
  function (event, $selected) {
    // Get Selected Element Information
    const href = $selected.data("href");
    const endpoint = $selected.data("endpoint");
    const id = $selected.data("id");
    const $dropdown = $selected.parents(".dropdown");
    const $input = $dropdown.find(".js-autocomplete");

    // Replace the Input Content by the Obsession href
    $input.val(href);

    // Get the last created episode href
    $.ajax({
      url: `${endpoint}/${id}/prepare-next-episode?select=href`,
      method: "GET",
      dataType: "json",
      headers: {
        Accept: "application/json",
      },
    }).done(function (data) {
      $(".episodes-page .js-previous-href .text-success").text(data.last?.href || "Aucun épisode précédent");

      if (data.subtitle && data.subtitle !== "") {
        // Update the subtitle and short inputs
        $("input[name=subtitle], input[name=short]").each((idx, input) => {
          $(input).closest(".edith-editor").setEdithEditorContent(data.subtitle);
          $(input).siblings(".edith-element").trigger("keyup");
        });
      }
    });
  }
);

// Listen Add Author/ Update Editor Autocomplete Selection Events
$body.on("change.episode", ".episodes-page #add-author .js-select", function (event) {
  // Get Selected Element Information
  const $selected = $(event.currentTarget).find("option:selected");

  // Check if the Selected Option is "Other"
  if ($selected.val() === "other") {
    // Show the Other Field
    $selected.parents(".modal-body").find(".js-add-author-other").removeClass("d-none");
  } else {
    // Hide & Clean the Other Field
    $selected.parents(".modal-body").find("input[name=other]").val("");
    $selected.parents(".modal-body").find(".js-add-author-other").addClass("d-none");
  }
});
// Listen Add Author/ Update Editor Autocomplete Selection Events
$body.on(
  "autocomplete.selected.episode",
  ".episodes-page #add-author .js-autocomplete, .episodes-page #update-editor .js-autocomplete",
  function (event, $selected) {
    // Get Selected Element Information
    const id = $selected.data("id");
    const text = $selected.data("text");
    const $dropdown = $selected.parents(".dropdown");
    const $input = $dropdown.find(".js-autocomplete");

    // Update the Autocomplete
    $input.data("author", id);
    $input.data("text", text);
    $input.val(text);
  }
);
// Listen Keyup Events on the Author/Editor Autocomplete Input
$body.on(
  "change.episode",
  ".episodes-page #add-author .js-autocomplete, .episodes-page #update-editor .js-autocomplete",
  function (event) {
    // Get the jQuery Input
    const $input = $(event.currentTarget);

    // Check if the Value has Change
    if ($input.val() !== $input.data("text")) {
      // Reset data-attributes
      $input.removeData("author");
      $input.removeData("text");
    }
  }
);
// Update the Author/Editor Value before Any Submit
$body.on(
  "click.episode",
  ".episodes-page #add-author form .btn-primary, .episodes-page #update-editor form .btn-primary",
  function (event) {
    // Get the jQuery Input
    const $clicked = $(event.currentTarget);
    const $form = $clicked.parents("form");
    const $author = $form.find("input[name=author], input[name=editor]");

    // Check if the Input has a data-author attribute
    if ($author.data("author")) {
      // Replace the Value by it's data-author attribute
      $author.val($author.data("author"));
    }

    // Submit the Form
    $form.get(0).submit();
  }
);

// Listen Add Image Autocomplete Selection Events
$body.on("autocomplete.selected.episode", ".episodes-page #slideshow .js-autocomplete", function (event, $selected) {
  // Get Selected Element Information
  const id = $selected.data("id");
  const endpoint = $selected.data("endpoint");

  // Get the Card
  $.ajax({
    url: endpoint + "/card/" + id,
    method: "GET",
    dataType: "html",
    headers: {
      Accept: "text/html",
    },
  }).done(function (card) {
    // Append the Card to the List
    $selected.parents(".tab-pane").find("form .card-deck-wrapper > .row").append(card);
  });
});

// Listen Click Events on .btn-danger Buttons on Card
$body.on("click.episode", ".episodes-page #slideshow .card .btn-danger", function (event) {
  // Remove the Card From the DOM
  $(event.currentTarget).parents(".card").parent().remove();
});

// Listen Article Tab Hide Events
$body.on(
  "hide.bs.tab",
  [
    ".episodes-page button[data-bs-target='#article']",
    ".episodes-page button[data-bs-target='#a-side']",
    ".episodes-page button[data-bs-target='#b-side']",
  ].join(", "),
  function (event) {
    const $currentTab = $(event.target);
    const $targetedTab = $(event.relatedTarget);
    // Check if we need a confirmation Modal
    if (
      $currentTab.data("skipConfirmationModal") !== true &&
      $(`${$currentTab.attr("data-bs-target")} button[data-editor-function='save']`).hasClass("btn-danger")
    ) {
      // Prevent the Tab Navigation
      event.preventDefault();

      // Show the Modal
      $.showConfirmationModal(
        "Article non sauvegardé",
        "Attention, l’article n’a pas été sauvegardé et cela peut entraîner un décalage avec les enrichissements affichés comme utilisés. Souhaitez-vous sauvegarder l’article ?",
        function (response) {
          if (response === true) {
            // Save the Editor
            $(".episodes-page button[data-editor-function='save']").trigger("click");
          }

          // Go to the targeted Tab
          $currentTab.data("skipConfirmationModal", true);
          $targetedTab.tab("show");
        },
        {
          confirmBtnTxt: "Sauvegarder",
          cancelBtnTxt: "Continuer sans sauvegarder",
        }
      );
    }
  }
);

// Listen Article Tab Hidden Events
$body.on(
  "hidden.bs.tab",
  [
    ".episodes-page button[data-bs-target='#article']",
    ".episodes-page button[data-bs-target='#a-side']",
    ".episodes-page button[data-bs-target='#b-side']",
  ],
  function (event) {
    // Remove the data once the Tab is shown
    $(event.target).removeData("skipConfirmationModal");
  }
);

// Listen Minis/Notes Modal Display Events
$body.on(
  "click.episode",
  [".episodes-page button[data-bs-target='#add-note']", ".episodes-page button[data-bs-target='#add-mini']"].join(", "),
  function () {
    const $button = $(this);
    const $modal = $($button.attr("data-bs-target"));
    const $form = $modal.find("form");

    // Reset Modal Forms
    $form.trigger("reset");
    $form.find("input[type=hidden]").val("");
    $form.find(".edith-editor").each((idx, el) => $(el).setEdithEditorContent(""));
    $modal.find(".js-selected-content").html("");

    if ($modal.find("#add-board-mini").length > 0) {
      $modal.find("#add-board-mini select option[value=people]").prop("selected", true);
      $modal.find("#add-board-mini select.js-media-type").trigger("change.episode");
    }

    // Check if we need to display a given tab
    if ($button.attr("data-tab-target") !== undefined) {
      $(`.nav-link[data-bs-target="${$button.attr("data-tab-target")}"]`).tab("show");
    }

    // Add the data to know if we need to insert the link
    $form.data("articleArea", $button.parents("#article, #a-side, #b-side"));
  }
);

// Listen ajaxFormProcess on Minis/Notes forms
$body.on("ajaxFormProcess", "#add-note form, #add-mini form, #notes form, #minis form", function (event, data) {
  // Check if we have data
  if (data === undefined || data === false) {
    // Nothing to do
    return;
  }

  // Get the Form
  const $form = $(this);
  const attribute = $form.attr("data-ressource-type");

  // Insert the link
  if ($form.data("articleArea") && $form.data("articleArea").length > 0) {
    const created = data[attribute][data[attribute].length - 1];
    $form.data("articleArea").find(".wysiwyg-editor").trigger("wysiwyg.insertlink", [attribute, created]);
  }

  // Replace the minis/notes list on the Board and Select
  $(`.card-deck-wrapper[data-ajax-form-key="episode-form-${attribute}-cards"]`).replaceWith(
    $.renderTemplate(attribute === "notes" ? "episodesFormsNotesCards" : "episodesFormsMinisCards", data)
  );
  $(`select[data-ajax-form-key="episode-form-${attribute}-select"]`).replaceWith(
    $.renderTemplate(attribute === "notes" ? "episodesFormsNotesSelect" : "episodesFormsMinisSelect", data)
  );

  // Reload Edith editors
  $(`.card-deck-wrapper[data-ajax-form-key="episode-form-${attribute}-cards"] .edith-editor`).initializeEdithEditor();
});

// Listen Update Video Autocomplete Selection Events
$body.on(
  "autocomplete.selected.episode",
  ".episodes-page #episode input[name=copyOf].js-autocomplete",
  function (event, $selected) {
    // Get Selected Element Information
    const id = $selected.data("id");
    const $input = $(event.currentTarget);

    // Put the ID in the Input Field
    $input.val(id);

    // Submit the Form
    $input.parents("form").trigger("submit");
  }
);

// Listen if the User pick a Language in the Dropdown of the Add Language Modal
$body.on("click.episode", ".episodes-page #add-language .dropdown-item", function (event) {
  const $button = $(event.currentTarget);
  const $form = $button.parents("form");
  const $input = $form.find("input[name=language]");
  const $toggle = $form.find(".dropdown-toggle");

  // Update the Dropdown Button
  $input.val($button.data("language"));
  $toggle.html($button.html());
});

// Listen if the User pick a Language in the Dropdown of the Editor
$body.on("click.episode", ".episodes-page #change-language .dropdown-item", function (event) {
  const $button = $(event.currentTarget);
  const $dropdown = $button.parents(".dropdown");

  // Load the Correct Language
  $.ajax({
    url: "/episodes/" + $dropdown.data("episode") + "/translation/" + $button.data("language"),
    method: "GET",
    dataType: "html",
    headers: {
      Accept: "text/html",
    },
  }).done(function (html) {
    // Check if we have html code
    if (html) {
      // Update the DOM Content
      $(html).each(function (idx, el) {
        // Replace Each Element in the DOM that have the Same Key
        const $el = $(el);
        $("[data-ajax-form-key=" + $el.data("ajaxFormKey") + "]").replaceWith($el);
      });
    }

    // Trigger Event to the Body
    $body.trigger("ajaxFormProcessed", true);

    // Create the Editor
    $(".episodes-page #article .wysiwyg-editor").wysiwyg();
  });
});

// Listen if the User delete a Language in the Dropdown of the Editor
$body.on("click.episode", ".episodes-page #change-language .js-delete-translation", function (event) {
  const $button = $(event.currentTarget);

  // Delete the Correct Language
  $.ajax({
    url: "/episodes/" + $button.data("episode") + "/translation/" + $button.data("language"),
    method: "DELETE",
    dataType: "html",
    headers: {
      Accept: "text/html",
    },
  }).done(function () {
    // Reload the Page
    window.location.reload(true);
  });
});

// Listen if the User pick a Tag in the Dropdown
$body.on("click.episode", ".episodes-page .js-add-tags .dropdown-item", function (event) {
  const $button = $(event.currentTarget);
  const $form = $button.parents("form");
  const $tags = $form.find(".js-tags-list");

  // Add a new Tag
  $("<span>", { class: "badge rounded-pill bg-primary p-2 me-2 mb-2" })
    .append($("<input>", { type: "hidden", name: "tags", value: $button.data("word") }))
    .append($button.data("word"))
    .append($("<span>", { class: "fa-solid fa-xmark pointer ms-1" }))
    .appendTo($tags);

  // Clear the Dropdown
  $form.find(".js-add-tags .js-autocomplete").val("");
});

// Listen if the User remove a Tag in the List
$body.on("click.episode", ".episodes-page .js-tags-list .badge", function (event) {
  // Remove the Tag
  $(event.currentTarget).remove();
});

// Listen if the User want to remove all Tags
$body.on("click.episode", ".episodes-page .js-tags-list .js-delete-all-tags", function (event) {
  // Remove the Tag
  $(event.currentTarget).parents(".js-tags-list").find(".badge").remove();
});

// Listen if the User want to Create a New Tag
$body.on("click.episode", ".episodes-page .js-add-tags .js-create-new-tag", function (event) {
  const $button = $(event.currentTarget);
  const $form = $button.parents("form");
  const $tags = $form.find(".js-tags-list");
  const $dropdown = $button.parents(".dropdown");
  const $input = $dropdown.find(".js-autocomplete");

  // Create a New Tag
  $.ajax({
    url: "/tags",
    method: "POST",
    data: {
      word: $input.val(),
    },
    dataType: "json",
    headers: {
      Accept: "application/json",
    },
  })
    .done(function (tag) {
      // Add a new Tag
      $("<span>", { class: "badge rounded-pill bg-primary p-2 me-2 mb-2" })
        .append($("<input>", { type: "hidden", name: "tags", value: tag.word }))
        .append(tag.word)
        .append($("<span>", { class: "fa-solid fa-xmark pointer ms-1" }))
        .appendTo($tags);

      // Clear the Dropdown
      $form.find(".js-add-tags .js-autocomplete").val("");
    })
    .fail(function () {
      // Clear the Dropdown
      $form.find(".js-add-tags .js-autocomplete").val("");
    });
});

// Listen if the User pick a Live Post in the Dropdown of the Editor
$body.on("click.episode", ".episodes-page #live-post-menu .dropdown-item", function (event) {
  const $button = $(event.currentTarget);
  const $dropdown = $button.parents(".dropdown");

  // Load the Correct Post
  $.ajax({
    url: `/episodes/${$dropdown.data("episode")}/post/${$button.data("post")}`,
    method: "GET",
  })
    .done(function (html) {
      // Update the DOM Content
      $(html).each(function (idx, el) {
        // Replace Each Element in the DOM that have the Same Key
        const $el = $(el);
        $(`[data-ajax-form-key=${$el.data("ajaxFormKey")}]`).replaceWith($el);
      });

      // Create the Editor & the Datetime Picker
      $(".episodes-page #article .js-datetime-picker").initializeDatetimePicker();
      $(".episodes-page #article .wysiwyg-editor").wysiwyg();
    })
    .fail(function () {
      // Show an Error Message
      $.notifyDanger({
        title: "Oups !",
        message: `Le post #${$button.data("post")} n’existe pas dans cet épisode.`,
      });
    });
});

// Listen if the User add a new Live Post
$body.on("click.episode", ".episodes-page #live-post-menu #add-live-post", function (event) {
  const $button = $(event.currentTarget);
  const episodeId = $button.data("episode");

  $.ajaxFormProcessPromise(
    // Load a New Post
    $.ajax({
      url: `/episodes/${episodeId}/post`,
      method: "POST",
    }),
    function (success) {
      // Check if it's a success
      if (!success) {
        // Stop processing the event
        return;
      }

      // Create the Editor & the Datetime Picker
      $(".episodes-page #article .js-datetime-picker").initializeDatetimePicker();
      $(".episodes-page #article .wysiwyg-editor").wysiwyg();
    }
  );
});

// Listen to show events on confirmation modal
$body.on("click.episode", ".episodes-page button.ask-confirmation", function () {
  const $button = $(this);

  switch ($button.data("action")) {
    case "supprimer":
      $.showConfirmationModal(
        "Confirmation de suppression",
        "Souhaitez-vous confirmer la suppression du post ?",
        function (response) {
          if (response === true) {
            deletePost($button);
          }
        }
      );
      break;
    case "publier":
      $.showConfirmationModal(
        "Confirmation de publication",
        "Souhaitez-vous confirmer la publication du post ?",
        function (response) {
          if (response === true) {
            publishOrUnpublishPost($button);
          }
        }
      );
      break;
    case "depublier":
      $.showConfirmationModal(
        "Confirmation de dépublication",
        "Souhaitez-vous confirmer la dépublication du post ?",
        function (response) {
          if (response === true) {
            publishOrUnpublishPost($button);
          }
        }
      );
      break;
    default:
  }
});

// Create the Editor
$(
  [
    ".episodes-page #article .wysiwyg-editor",
    ".episodes-page #a-side  .wysiwyg-editor",
    ".episodes-page #b-side  .wysiwyg-editor",
  ].join(",")
).each(function () {
  $(this).wysiwyg();
});

// Listen A-Side / B-Side Images Change Events
$body.on(
  "change.episode",
  [".episodes-page #a-side form input[name='image']", ".episodes-page #b-side form input[name='image']"].join(","),
  function (event) {
    // Get Elements
    const $form = $(event.currentTarget).parents("form");
    const reader = new FileReader();

    // Listen Load Event
    reader.onload = function () {
      $form.find(".js-image-preview").html(
        $("<img>", {
          class: "rounded img-fluid",
          src: reader.result,
        })
      );
    };

    // Read the Image
    reader.readAsDataURL(event.target.files[0]);
  }
);

// Listen change events on the CTA checkbox
$body.on(
  "change.episode.cta",
  ".episodes-page .editor-insert-cta input[name=cta-donation]",
  function (event, href = "", checked = true) {
    const $button = $(event.target);
    const isDonationLink = $button.is(":checked");
    const $modal = $button.closest(".modal");
    const $link = $modal.find("input[name=cta-link]");
    const $tabTarget = $modal.find("input[name=cta-tab-target]");

    // Disable/enable and prefill inputs
    $link.val(isDonationLink ? "https://donorbox.org/les-jours" : href);
    $link.prop("disabled", isDonationLink);
    $tabTarget.prop("checked", checked);
    $tabTarget.prop("disabled", isDonationLink);
  }
);

// Listen clicks on fcmToken button
$("#test-push").on("click.episode", "[data-fill-fcmtoken]", function (event) {
  const $button = $(event.currentTarget);
  const $form = $button.parents("form");

  $form.find("[name=fcmToken]").val($button.data("fillFcmtoken"));
});

// Test if the Google Showcase field is valid
function validateGoogleShowcaseField() {
  const $subtitleEdith = $(".episodes-page #episode .edith-editor:has(input[name=subtitle])");
  const $sentencesEdith = $(".episodes-page #episode .edith-editor:has(input[name=sentences])");

  let sentencesText = $sentencesEdith.getEdithEditorContent();
  if (sentencesText === "") {
    sentencesText = $subtitleEdith.getEdithEditorContent();
  }
  if ($sentencesEdith.data("isScoop") === true) {
    sentencesText = `<b>Info «&nbsp;Les&nbsp;Jours&nbsp;».</b> ${sentencesText}`;
  } else if ($sentencesEdith.data("isNewSerie") === true) {
    sentencesText = `<b>Nouvelle série.</b> ${sentencesText}`;
  } else if ($sentencesEdith.data("isNewSeason") === true) {
    sentencesText = `<b>Nouvelle saison.</b> ${sentencesText}`;
  }
  sentencesText = sentencesText.replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>|<!--[\s\S]*?-->/gi, "");

  const groups = (sentencesText.match(/[^.!?:]+(?:[.!?:]|$)/gm) ?? [])
    .map((el) => el.trim())
    .filter((el) => el?.length > 0);
  if (groups.length < 2 || 3 < groups.length) {
    $sentencesEdith.find(".alert").remove();
    $sentencesEdith.append(`
        <div class="alert alert-warning mt-1" role="alert">
          Aucun sous-titre valide pour Google Showcase 😢
        </div>
      `);
  } else {
    $sentencesEdith.find(".alert").remove();
    $sentencesEdith.append(`
        <div class="alert alert-light mt-1" role="alert">
          Aperçu&nbsp:<br />
          <ul class="m-0">
            <li>${groups.join("</li><li>")}</li>
          </ul>
        </div>
      `);
  }
}
if ($(".episodes-page #episode .edith-editor:has(input[name=sentences])").length > 0) {
  $body.on(
    "input.episode.sentences",
    ".episodes-page #episode .edith-editor:has(input[name=subtitle]), .episodes-page #episode .edith-editor:has(input[name=sentences])",
    throttle(validateGoogleShowcaseField, 1000, { leading: true, trailing: true })
  );
  $body.on(`ajaxFormProcessed.episode.sentences`, validateGoogleShowcaseField);
  validateGoogleShowcaseField();
}
