import $ from "jquery";

const $body = $("body");

// Listen Click on Delete Buttons in List Groups
$body.on("click.newsletters", ".newsletters-page .js-nl-add-episode .list-group-item .btn-danger", function (event) {
  const $button = $(event.currentTarget);

  // Remove the Element
  $button.closest(".list-group-item").remove();
});

// Listen Episodes Autocomplete Selection Events
$body.on(
  "autocomplete.selected.newsletters",
  ".newsletters-page .js-nl-add-episode .js-autocomplete",
  function (event, $selected) {
    // Get the Episode Area
    const $nlArea = $selected.parents(".js-nl-add-episode");

    // Get the Episode JSON
    $.ajax({
      url: $selected.data("endpoint") + "/card/" + $selected.data("id"),
      method: "GET",
      dataType: "json",
    }).done(function (data) {
      // Add a List Group Item for the Selected Element
      $nlArea.find(".list-group").prepend(
        $.renderTemplate("newslettersCardsArticle", {
          element: {
            model: data.endpoint === "/podcasts" ? "Podcast" : "Episode",
            article: {
              id: data.id,
              title: data.title,
              date: data.date,
              subtitle: data.subtitle,
            },
          },
        })
      );
    });
  }
);

// Listen Episodes Autocomplete Selection Events
$body.on(
  "autocomplete.selected.newsletters",
  ".newsletters-page .js-nl-add-bloc-modal .js-autocomplete, .newsletters-page #edit .card-deck-wrapper[data-input-name='elements'] .js-autocomplete",
  function (event, $selected) {
    // Get the dropdown
    const $dropdown = $selected.parents(".dropdown");

    // Add the ID & the model to the hidden inputs
    $dropdown.find("input[type=hidden][name!=model]").val($selected.data("id"));
    $dropdown.find("input[name=model]").val($selected.data("model"));

    // Show the Text in the visible input
    $dropdown.find(".js-autocomplete").val($selected.data("text"));
  }
);

// Listen Click on Add Buttons
$body.on("click.newsletters", ".newsletters-page .js-nl-add-bloc-modal .btn-primary", function (event) {
  // Get the Newsletter Area
  const $nlArea = $(".newsletters-page #edit .card-deck-wrapper[data-input-name='elements']");

  // Get the Modal & save Edith content to value attributes
  const $ndAddModal = $(event.currentTarget).parents(".js-nl-add-bloc-modal");
  $ndAddModal.find(".edith-editor").saveEdithEditorInValue();

  // Check the ID to generate the data
  let element;
  switch ($ndAddModal.attr("id")) {
    case "add-opinion-bloc":
      element = {
        kind: "EditoNewsletterOpinionElement",
        title: $ndAddModal.find("input[name=title]").val(),
        text: $ndAddModal.find("input[name=text]").val(),
        author: {
          id: $ndAddModal.find("input[name=author]").val(),
          name: $ndAddModal.find("input.js-autocomplete").val(),
        },
      };
      break;
    case "add-picture-bloc":
      element = {
        kind: "EditoNewsletterPictureElement",
        title: $ndAddModal.find("input[name=title]").val(),
        text: $ndAddModal.find("input[name=text]").val(),
        image: {
          id: $ndAddModal.find("input[name=image]").val(),
          alt: $ndAddModal.find("input.js-autocomplete").val(),
        },
      };
      break;
    case "add-marketing-bloc":
      element = {
        kind: "EditoNewsletterMarketingElement",
        title: $ndAddModal.find("input[name=title]").val(),
        text: $ndAddModal.find("input[name=text]").val(),
        action: {
          text: $ndAddModal.find("input[name='action.text']").val(),
          link: $ndAddModal.find("input[name='action.link']").val(),
        },
      };
      break;
    case "add-serie-bloc":
      element = {
        kind: "EditoNewsletterSerieElement",
        title: $ndAddModal.find("input[name=title]").val(),
        subtitle: $ndAddModal.find("input[name=subtitle]").val(),
        text: $ndAddModal.find("input[name=text]").val(),
        model: $ndAddModal.find("input[name=model]").val(),
        serie: {
          id: $ndAddModal.find("input[name=serie]").val(),
          title: $ndAddModal.find("input.js-autocomplete").val(),
        },
      };
      break;
    case "add-text-bloc":
      element = {
        kind: "EditoNewsletterTextElement",
        title: $ndAddModal.find("input[name=title]").val(),
        headline: $ndAddModal.find("input[name=headline]").val(),
        text: $ndAddModal.find("input[name=text]").val(),
        color: $ndAddModal.find("input[name=color]").val(),
        action: {
          text: $ndAddModal.find("input[name='action.text']").val(),
          link: $ndAddModal.find("input[name='action.link']").val(),
        },
      };
      break;
    case "add-cherry-picks-bloc":
      element = {
        kind: "EditoNewsletterCherryPicksElement",
        title: $ndAddModal.find("input[name=title]").val(),
        picks: [],
      };
      break;
    case "add-press-review-bloc":
      element = {
        kind: "EditoNewsletterPressReviewElement",
        title: $ndAddModal.find("input[name=title]").val(),
        links: [],
      };
      break;
    case "add-surprise-bloc":
      element = {
        kind: "EditoNewsletterSurpriseElement",
        title: $ndAddModal.find("input[name=title]").val(),
        text: $ndAddModal.find("input[name=text]").val(),
        action: {
          text: $ndAddModal.find("input[name='action.text']").val(),
          link: $ndAddModal.find("input[name='action.link']").val(),
        },
        image: $ndAddModal.find("input[name=image]").val(),
      };
      break;
    case "add-ending-bloc":
      element = {
        kind: "EditoNewsletterEndingElement",
        title: $ndAddModal.find("input[name=title]").val(),
        text: $ndAddModal.find("input[name=text]").val(),
        author: {
          id: $ndAddModal.find("input[name=author]").val(),
          name: $ndAddModal.find("input.js-autocomplete").val(),
        },
      };
      break;
    default:
      return;
  }

  // Add a Card for the Element
  $nlArea.append(
    $.renderTemplate("newslettersCardsElement", {
      element: element,
    })
  );

  // Load Edith editors & ColorPickers
  $nlArea.find(".edith-editor").initializeEdithEditor();
  $nlArea.find(".js-color-picker").initializeColorPickers();
});

// Listen Click on Add Link/Pick Buttons in Cards
$body.on(
  "click.newsletters",
  ".newsletters-page #edit .card-deck-wrapper .js-add-pick-button, .newsletters-page #edit .card-deck-wrapper .js-add-link-button",
  function (event) {
    const $button = $(event.currentTarget);

    // Get the Area
    const $area = $button.closest(".card").find(".card-deck-wrapper");

    // Add a Pick/Link Element
    if ($button.hasClass("js-add-link-button")) {
      $area.append($.renderTemplate("newslettersCardsLink", { link: {} }));
    } else {
      $area.append($.renderTemplate("newslettersCardsPick", { pick: {} }));
    }

    // Load Edith editors
    $area.find(".edith-editor").initializeEdithEditor();
  }
);

// Listen Click on Delete Buttons in Cards
$body.on(
  "click.newsletters",
  ".newsletters-page #edit .card-deck-wrapper[data-input-name='elements'] .card .btn-danger",
  function (event) {
    const $button = $(event.currentTarget);

    // Remove the Element
    $button.closest(".card").remove();
  }
);

// Listen Click on the Submit Button in Newsletters Forms
$body.on(
  "click.newsletters",
  ".newsletters-page #edit form .btn-success[type=submit], .newsletters-page #save-newsletter-tab",
  function (event) {
    // Prevent default
    event.preventDefault();
    event.stopPropagation();

    // Get the Form & save Edith content to value attributes
    const $form = $(".newsletters-page #edit form");
    $form.find(".edith-editor").saveEdithEditorInValue();

    // Prepare the common newsletter payload
    const newsletter = {
      title: $form.find('input[name="title"]').val(),
      subject: $form.find('input[name="subject"]').val(),
      preview: $form.find('input[name="preview"]').val(),
    };

    // Check the Newsletter model
    if ($form.data("model") === "EditoNewsletter") {
      // Add the EditoNewsletter data
      newsletter.elements = $form
        .find('[data-input-name="elements"] .card')
        .map((idx, el) => {
          const $element = $(el);
          const element = {
            kind: $element.find("input[name=kind]").val(),
            title: $element.find("input[name=title]").val(),
            restrict: $element.find("select[name=restrict]").val(),
          };
          if (element.restrict === "") {
            delete element.restrict;
          }
          switch (element.kind) {
            case "EditoNewsletterOpinionElement":
              element.text = $element.find("input[name=text]").val();
              element.author = $element.find("input[name=author]").val();
              break;
            case "EditoNewsletterPictureElement":
              element.text = $element.find("input[name=text]").val();
              element.image = $element.find("input[name=image]").val();
              break;
            case "EditoNewsletterMarketingElement":
              element.text = $element.find("input[name=text]").val();
              element.action = {
                text: $element.find("input[name='action.text']").val(),
                link: $element.find("input[name='action.link']").val(),
              };
              break;
            case "EditoNewsletterSerieElement":
              element.subtitle = $element.find("input[name=subtitle]").val();
              element.text = $element.find("input[name=text]").val();
              element.model = $element.find("input[name=model]").val();
              element.serie = $element.find("input[name=serie]").val();
              break;
            case "EditoNewsletterTextElement":
              element.headline = $element.find("input[name=headline]").val();
              element.text = $element.find("input[name=text]").val();
              element.color = $element.find("input[name=color]").val();
              element.action = {
                text: $element.find("input[name='action.text']").val(),
                link: $element.find("input[name='action.link']").val(),
              };
              if (element.headline === "") {
                delete element.headline;
              }
              if (element.action.text === "" && element.action.link === "") {
                delete element.action;
              }
              break;
            case "EditoNewsletterCherryPicksElement":
              element.picks = $element
                .find(".card-deck-wrapper .card")
                .map((i, pick) => {
                  const $pick = $(pick);
                  const pickEntry = {
                    headline: $pick.find("input[name='pick.headline']").val(),
                    figure: $pick.find("input[name='pick.figure']").val(),
                    subtitle: $pick.find("input[name='pick.subtitle']").val(),
                    image: $pick.find("input[name='pick.image']").val(),
                    credit: $pick.find("input[name='pick.credit']").val(),
                    text: $pick.find("input[name='pick.text']").val(),
                    action: {
                      text: $pick.find("input[name='pick.action.text']").val(),
                      link: $pick.find("input[name='pick.action.link']").val(),
                    },
                  };
                  if (pickEntry.figure === "") {
                    delete pickEntry.figure;
                  }
                  if (pickEntry.subtitle === "") {
                    delete pickEntry.subtitle;
                  }
                  if (pickEntry.image === "") {
                    delete pickEntry.image;
                    delete pickEntry.credit;
                  } else if (pickEntry.credit === "") {
                    delete pickEntry.credit;
                  }
                  if (pickEntry.action.text === "" && pickEntry.action.link === "") {
                    delete pickEntry.action;
                  }
                  return pickEntry;
                })
                .get();
              break;
            case "EditoNewsletterPressReviewElement":
              element.links = $element
                .find(".card-deck-wrapper .card")
                .map((i, link) => {
                  const $link = $(link);
                  return {
                    title: $link.find("input[name='link.title']").val(),
                    text: $link.find("input[name='link.text']").val(),
                  };
                })
                .get();
              break;
            case "EditoNewsletterSurpriseElement":
              element.text = $element.find("input[name=text]").val();
              element.action = {
                text: $element.find("input[name='action.text']").val(),
                link: $element.find("input[name='action.link']").val(),
              };
              element.image = $element.find("input[name=image]").val();
              element.credit = $element.find("input[name=credit]").val();
              if (element.image === "") {
                delete element.image;
                delete element.credit;
              } else if (element.credit === "") {
                delete element.credit;
              }
              if (element.action.text === "" && element.action.link === "") {
                delete element.action;
              }
              break;
            case "EditoNewsletterEndingElement":
              element.text = $element.find("input[name=text]").val();
              element.author = $element.find("input[name=author]").val();
              break;
            default:
              return;
          }
          return element;
        })
        .get();
    } else {
      // Add the DailyNewsletter data
      newsletter.header = {
        subscribers: $form.find('input[name="header.subscribers"]').val(),
        prospects: $form.find('input[name="header.prospects"]').val(),
      };
      newsletter.footer = {
        subscribers: $form.find('input[name="footer.subscribers"]').val(),
        prospects: $form.find('input[name="footer.prospects"]').val(),
      };
      newsletter.dailyArticles = $form
        .find('[data-input-name="dailyArticles"] .list-group-item')
        .map((idx, dailyArticle) => {
          const $dailyArticle = $(dailyArticle);
          return {
            model: $dailyArticle.find('input[name="model"]').val(),
            article: $dailyArticle.find('input[name="article"]').val(),
          };
        })
        .get();
      newsletter.articles = $form
        .find('[data-input-name="articles"] .list-group-item')
        .map((idx, article) => {
          const $article = $(article);
          return {
            model: $article.find('input[name="model"]').val(),
            article: $article.find('input[name="article"]').val(),
          };
        })
        .get();
    }

    // Send the NL Form
    $.ajaxFormProcessPromise(
      $.ajax({
        method: $form.attr("method").toUpperCase(),
        url: $form.attr("action"),
        contentType: "application/json; charset=UTF-8",
        data: JSON.stringify(newsletter),
      })
    );
  }
);
