
      import pug from 'pug-runtime';

      function marketingToolboxUtmsLinks(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (utms) {
      pug_html = pug_html + "\u003Cdiv class=\"col-12 js-utms-links\"\u003E\u003Cp\u003EEn cliquant sur une ligne l’URL sera copiée dans le presse-papier.\u003C\u002Fp\u003E\u003Cdiv class=\"list-group\"\u003E";
// iterate utms
;(function(){
  var $$obj = utms;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var utm = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (" class=\"list-group-item list-group-item-action pointer\""+pug.attr("data-to-clipboard", utm.link, true, false)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes([utm.icon + " me-1"], [true]), false, false)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = utm.title) ? "" : pug_interp)) + "&nbsp;: " + (pug.escape(null == (pug_interp = utm.link) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var utm = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (" class=\"list-group-item list-group-item-action pointer\""+pug.attr("data-to-clipboard", utm.link, true, false)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes([utm.icon + " me-1"], [true]), false, false)) + "\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = utm.title) ? "" : pug_interp)) + "&nbsp;: " + (pug.escape(null == (pug_interp = utm.link) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "utms" in locals_for_with ?
        locals_for_with.utms :
        typeof utms !== 'undefined' ? utms : undefined));
    ;;return pug_html;}

      export default marketingToolboxUtmsLinks;
    