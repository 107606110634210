import $ from "jquery";

const $body = $("body");

// Listen Meta-Person Search Autocomplete Selection Events
$body.on(
  "autocomplete.selected.media",
  ".metapersons-page #search-metaperson .js-autocomplete",
  function (event, $selected) {
    // Get Selected Element Information
    const id = $selected.data("id");

    // Navigate to the Meta-Person Page
    window.location.href = "/metapersons/" + id;
  }
);

// Listen Add Meta-Person Autocomplete Selection Events
$body.on(
  "autocomplete.selected.metaperson",
  ".metapersons-page #metaperson .js-autocomplete",
  function (event, $selected) {
    // Get Selected Element Information
    const id = $selected.data("id");
    const $pane = $selected.parents("#metaperson");
    const metaperson = $pane.data("metaperson");

    // Call the Add Meta-Person URL
    $.ajax({
      url: "/metapersons/" + metaperson + "/people",
      method: "POST",
      contentType: "application/x-www-form-urlencoded; charset=UTF-8",
      data: { id: id },
    }).done(function () {
      // Reload the Page on the Correct Tab
      window.location.href = window.location.href.replace(/#[a-zA-Z0-9-]+$/, "") + "#metaperson";
      window.location.reload(true);
    });
  }
);

// Listen Clicks on Meta-Person Delete Button
$body.on("click.metaperson", ".metapersons-page #metaperson .media-card .btn-danger", function (event) {
  const $clicked = $(event.currentTarget);
  const $card = $clicked.parents(".media-card");
  const input = $card.find("input[name=people]");
  const id = input.val();
  const $pane = $clicked.parents("#metaperson");
  const metaperson = $pane.data("metaperson");

  // Call the Delete Meta-Person URL
  $.ajax({
    url: "/metapersons/" + metaperson + "/people/" + id,
    method: "DELETE",
  })
    .done(function () {
      // Reload the Page on the Correct Tab
      window.location.href = window.location.href.replace(/#[a-zA-Z0-9-]+$/, "") + "#metaperson";
      window.location.reload(true);
    })
    .fail(function (jqXHR) {
      // Check if we have an Error Message
      if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
        // Show an Error Message
        $.notifyDanger({
          title: "Oups !",
          message: jqXHR.responseJSON.message,
        });
      }
    });
});

// Listen Changes on Meta-Persons Search
$body.on("input.marketing", ".metapersons-page #metapersons-last .js-metapersons-search", function () {
  // Get the Meta-Persons list area
  const $area = $(".metapersons-page #metapersons-last");
  const $list = $area.find(".js-metapersons-list");

  // Get search data
  const request = $area.find(".js-metapersons-search").val();

  // Don’t send the request in particular cases
  if (request.length >= 1 && request.length < 3) {
    return;
  }

  // If there is no search request
  if (request.length === 0) {
    // Get the last Meta-Persons list
    $.ajax({
      url: `/metapersons`,
      method: "GET",
      dataType: "html",
      headers: {
        Accept: "text/html",
      },
    }).done(function (list) {
      // Append the List
      $list.replaceWith(list);
    });
    return;
  }

  // Get the Meta-Persons searched list
  $.ajax({
    url: "/metapersons/cards",
    method: "GET",
    dataType: "html",
    headers: {
      Accept: "text/html",
    },
    data: {
      request: request,
    },
  }).done(function (list) {
    // Append the List
    $list.replaceWith(list);
  });
});
