import $ from "jquery";
import uniq from "lodash/uniq";

const $body = $("body");

// A function to copy an Input Value and paste it in all Corresponding Fields
function copyAndPasteFieldValue($input, value, isEdith) {
  // Get the Form & Corresponding Inputs Elements
  const $form = $input.parents("form");
  const $targets = $form.find("input, select, textarea").filter('[name="' + $input.attr("name") + '"]');

  // If the targets aren’t Edith Editors
  if (!isEdith) {
    // Paste the value in all Corresponding Inputs
    $targets.each(function (idx, el) {
      $(el).val(value);
    });
    return;
  }

  // Paste the value in all Corresponding Edith Editors
  $targets.closest(".edith-editor").each(function (idx, el) {
    $(el).setEdithEditorContent(value);
  });
}

// A function to check the availability of a reusability
function checkReusabilityValidity() {
  const $select = $(".medias-page select[name=reusability]");
  const $selected = $select.find("option:selected");

  // Remove Inputs invalidation
  $select.removeClass("is-invalid");

  // Get selected values
  const selected = $selected.map((i, s) => s.value).get();
  const duplicates = selected.filter((s, i) => selected.indexOf(s) !== i);

  // Invalid inputs
  $selected.each((i, option) => {
    const $parent = $(option).parents("select");
    if (duplicates.includes(option.value)) {
      // Invalid the input
      $parent.addClass("is-invalid");
      $parent.get(0).setCustomValidity("Un même droit ne peut être sélectionné qu’une seule fois.");
    } else {
      $parent.get(0).setCustomValidity("");
    }
  });
}

// Clean Files Inputs after Page Loading
$(".medias-page #add-media form, .medias-page #add-medias form").each(function (idx, el) {
  // Reset the Form
  el.reset();
});

// Listen Obsession Autocomplete Selection Events
$body.on(
  "autocomplete.selected.media",
  ".medias-page #add-media .js-autocomplete, .medias-page #add-medias .js-autocomplete",
  function (event, $selected) {
    // Get Selected Element Information
    const $pane = $selected.parents(".tab-pane");
    const $href = $pane.find("input[name=href]");
    const $documentInput = $pane.find("input[name=serieDocument]");
    const $modelInput = $pane.find("input[name=serieModel]");
    const model = $selected.data("model");

    // Update the Value in the Autocomplete Form
    $selected.parents(".dropdown").find("input").val($selected.find(".wrap-text").textContent());
    // Reset Href Input and Data
    $pane.removeData("preFillHref");

    // Check if it’s an Obsession or Programme
    if (model === "Obsession" || model === "Programme") {
      // Update the Input Form
      $documentInput.val($selected.data("id"));
      $modelInput.val(model);
      return;
    }

    // Get the Episode or Podcast informations
    $.ajax({
      url: `${$selected.data("endpoint")}/card/${$selected.data("id")}`,
      method: "GET",
      dataType: "json",
      headers: {
        Accept: "application/json",
      },
    }).done(function (data) {
      // Pre-fill HREF
      $pane.data("preFillHref", `${data.href}-`);
      $href.each((idx, href) => {
        $(href).val(`${data.href}-${idx + 1}`);
      });

      // Set the Document and Model Input values
      if (data.programme !== undefined) {
        $modelInput.val("Programme");
        $documentInput.val(data.programme.id);
      } else if (data.obsession !== undefined) {
        $modelInput.val("Obsession");
        $documentInput.val(data.obsession.id);
      }
    });
  }
);

// Listen Click on the Submit Button in Add Media Forms to check if the HREF Exist
$body.on(
  "click.media",
  ".medias-page #add-media button[type=submit], .medias-page #add-medias button[type=submit]",
  function (event) {
    // Get Images HREF Fields
    const $button = $(event.currentTarget);
    const $form = $button.parents("form");

    // Check if the form is valid
    if (!$form.get(0).checkValidity()) {
      // Nothing to do
      return;
    }

    // Clear Warnings
    $form.find(".is-invalid").removeClass("is-invalid");
    $form.find(".invalid-feedback").remove();

    // Check if all Fields are Differents
    const $inputs = $form.find("input[name=href]");
    const values = $inputs.map((idx, el) => $(el).val());
    if (uniq(values).length !== values.length) {
      // All Fields are not Differents
      // Prevent the form submission
      event.preventDefault();
      event.stopPropagation();

      // Display popover on duplicated Href
      for (const value of uniq(values)) {
        // Get inputs with the current value
        const $inputsGroup = $inputs.filter((idx, el) => $(el).val() === value);

        // Check if there is duplicated fields
        if ($inputsGroup.length > 1) {
          // There is duplicated Href
          // Show a Message on each Field
          $inputsGroup.each(function (idx, el) {
            $(el)
              .addClass("is-invalid")
              .parent()
              .append('<div class="invalid-feedback">Attention, ce champ href est dupliqué !</div>');
          });
        }
      }

      // Nothing else to do
      return;
    }

    // Check if we have a Serie field without value
    const $serie = $form.find("input[name=serieDocument]");
    if ($serie.length > 0 && $serie.val() === "") {
      // Prevent the form submission
      event.preventDefault();
      event.stopPropagation();

      // Ask for confirmation before copying to all fields
      $.showConfirmationModal(
        "Attention !",
        "Le média n’est associé à aucun·e obsession ou programme. Souhaitez-vous l’uploader quand même ?",
        function (response) {
          if (response === true) {
            $form.trigger("submit");
          }
        }
      );
    }
  }
);

// Listen Clicks on js-copy-field
$body.on("click.media", ".medias-page form button.js-copy-field", function (event) {
  // Get the Button and the Linked Input
  const $row = $(event.currentTarget).closest(".row");
  const $input = $row.find("input, select, textarea");
  const $edith = $input.closest(".edith-editor");
  const value = $edith.length > 0 ? $edith.getEdithEditorContent() : $input.val();

  // Ask for confirmation before copying to all fields
  $.showConfirmationModal(
    "Attention !",
    `Souhaitez-vous remplacer tous les champs correspondants par la valeur « ${value} » ?`,
    function (response) {
      if (response === true) {
        copyAndPasteFieldValue($input, value, $edith.length > 0);
      }
    }
  );
});

// Listen Clicks on Remove Media File Buttons
$(".medias-page #media .js-remove-media-file").on("click.media", function (event) {
  // Remove the Button's Parent
  $(event.currentTarget).parents(".card").remove();
});

// Listen Clicks on Remove Media File Buttons
$(".medias-page #media select[name=effect-type]").on("change.media", function (event) {
  // Update Related Forms
  const $formGroup = $(event.currentTarget).closest(".row");
  const $related = $formGroup.find("[data-effect]");

  // Hide & Disable all Related Forms
  $related.addClass("d-none").find("input, select").attr("disabled", "disabled");

  // Show Required Forms for the Selected Effect
  $formGroup
    .find('[data-effect="' + $(event.currentTarget).val() + '"]')
    .removeClass("d-none")
    .find("input, select")
    .removeAttr("disabled");
});

// Listen Media Search Autocomplete Selection Events
$body.on(
  "autocomplete.selected.mediaauthorpage",
  ".medias-page #media #authorpage-searchbox .js-autocomplete",
  function (event, $selected) {
    // Get Selected Element Information
    const id = $selected.data("id");
    const $searchbox = $(".medias-page #media #authorpage-searchbox");
    const $input = $searchbox.find("input");
    const endpoint = $input.data("endpoint");

    // Get & Save the Author Card
    $.ajax({
      url: endpoint + "/card/" + id,
      method: "GET",
      dataType: "json",
      headers: {
        Accept: "application/json",
      },
    }).done(function (data) {
      // Update the Author Card
      $(".medias-page #media .js-media-authorpage").html(
        $.renderTemplate("mediasFormsAuthorpage", { media: { authorPage: data } })
      );
    });
  }
);
$body.on(
  "autocomplete.selected.mediaauthor",
  ".medias-page #media #author-searchbox .js-autocomplete",
  function (event, $selected) {
    // Get Selected Element Information
    const id = $selected.data("id");
    const $searchbox = $(".medias-page #media #author-searchbox");
    const $input = $searchbox.find("input");
    const endpoint = $input.data("endpoint");

    // Get & Save the Author Card
    $.ajax({
      url: endpoint + "/card/" + id,
      method: "GET",
      dataType: "json",
      headers: {
        Accept: "application/json",
      },
    }).done(function (data) {
      // Update the Author Card
      $(".medias-page #media .js-media-author").html(
        $.renderTemplate("mediasFormsAuthor", { media: { author: data } })
      );
    });
  }
);

// Listen Changes on Media Search
$body.on("input.marketing", ".medias-page #medias-last .js-medias-search", function () {
  // Get the Medias list area
  const $area = $(".medias-page #medias-last");
  const $list = $area.find(".js-medias-list");

  // Get search data
  const $input = $area.find(".js-medias-search");
  const request = $input.val();
  const modelName = $input.data("modelName");

  // Don’t send the request in particular cases
  if (request.length >= 1 && request.length < 3) {
    return;
  }

  // If there is no search request
  if (request.length === 0) {
    // Get the last Medias list
    $.ajax({
      url: `/medias/${modelName}`,
      method: "GET",
      dataType: "html",
      headers: {
        Accept: "text/html",
      },
    }).done(function (list) {
      // Append the List
      $list.replaceWith(list);
    });
    return;
  }

  // Get the Medias searched list
  $.ajax({
    url: `/medias/${modelName}/cards`,
    method: "GET",
    dataType: "html",
    headers: {
      Accept: "text/html",
    },
    data: {
      request: request,
    },
  }).done(function (list) {
    // Append the List
    $list.replaceWith(list);
  });
});

// Listen Clicks on Remove Media File Buttons
$body.on("click.mediaauthorpage", ".medias-page #media .js-delete-media-authorpage", function () {
  // Drop the Author Card
  $(".medias-page #media .js-media-authorpage").html("");
});
$body.on("click.mediaauthor", ".medias-page #media .js-delete-media-author", function () {
  // Drop the Author Card
  $(".medias-page #media .js-media-author").html("");
});

// Listen Clicks on Delete Media Button
$body.on("click.media", ".medias-page .media-card .btn-danger", function (event) {
  const $clicked = $(event.currentTarget);
  const $card = $clicked.parents(".media-card");
  const id = $card.find("input[name=media]").val();
  const endpoint = $card.find("input[name=endpoint]").val();

  // Call the Delete Media URL
  $.ajax({
    url: `${endpoint}/${id}`,
    method: "DELETE",
  })
    .done(function () {
      // Remove the Media card
      $card.parent().remove();
    })
    .fail(function (error) {
      // Check if we have an Error Message
      if (error.responseJSON && error.responseJSON.error) {
        // Show an Error Message
        $.notifyDanger({
          title: "Oups !",
          message: error.responseJSON.error,
        });
      }
    });
});

// ///////////////////////////////////////////////////////////////////////////////////
// IMAGE REUSABILITY                                                                //
// ///////////////////////////////////////////////////////////////////////////////////
// Listen Changes on Reusability Selects
$body.on("change.mediareusability", ".medias-page select[name=reusability]", function () {
  const $select = $(this);
  const $expiration = $select.closest(".card").find(".js-reusability-expiration");

  // Replace the expiration input if it exists
  if ($expiration.length) {
    const expirationId = `expirations[${$select.find("option:selected").val()}]`;
    $expiration.find(".expiration").attr("name", expirationId);
    $expiration.find(".js-datetime-picker").attr("data-datetimepicker-target-input", expirationId);
  }

  // Check Reusability Validity
  checkReusabilityValidity();
});

// Listen Clicks on Add Reusability Input Button
$body.on("click.mediareusability", ".medias-page .js-add-reusability", function () {
  const $list = $(".js-reusability-list");

  // Remove the alert message if it exists
  if ($list.find(".alert").length > 0) {
    $list.empty();

    // Display the set not reusable button
    $(".js-not-reusable").parent().removeClass("d-none");
  }

  // Append the reusability form
  $list.append($.renderTemplate("mediasFormsReusability", {}));

  // Check Reusability Validity
  checkReusabilityValidity();
});

// Listen Clicks on Remove Reusability Input Buttons
$body.on("click.mediareusability", ".medias-page .js-remove-reusability", function () {
  // Remove the card
  $(this).closest(".card").remove();

  // Check the Reusability Validity
  const $list = $(".js-reusability-list");
  if ($list.find(".card").length > 0) {
    checkReusabilityValidity();
    return;
  }

  // Append an alert if there is no set Reusability
  $list.replaceWith($.renderTemplate("mediasFormsReusabilities", { reusability: { rights: [] } }));
});

// Listen Clicks on Set Reusability to not reusable
$body.on("click.mediareusability", ".medias-page .js-not-reusable", function () {
  $(".js-reusability-list").replaceWith($.renderTemplate("mediasFormsReusabilities", {}));
  $(this).parent().addClass("d-none");
});

// Listen Clicks on Generate Reusability Inputs Button
$body.on("click.mediareusability", ".medias-page .js-generate-reusability", function () {
  const $button = $(this);
  const $media = $button.parents("#media");
  const $list = $media.find(".js-reusability-list");
  const endpoint = $button.data("endpoint").toLowerCase();
  const mediaId = $button.data("id");
  const credit = $media.find("input[name=credit]").val();

  // Get the Image Reusability
  $.ajax({
    url: `${endpoint}/reusability/${mediaId}`,
    method: "POST",
    dataType: "html",
    headers: {
      Accept: "text/html",
    },
    data: {
      credit: credit,
    },
  }).done(function (list) {
    // Empty the list
    $list.empty();

    // Insert new rights after delay
    setTimeout(() => {
      $list.replaceWith(list);

      $(".js-reusability-list .js-datetime-picker").initializeDatetimePicker();
      $(".js-not-reusable").parent().removeClass("d-none");
    }, 500);
  });
});

// Listen Clicks on Toggle Reusability Expiration Input
$body.on("click.mediareusability", ".medias-page .js-toggle-expiration", function () {
  const $item = $(this).closest(".card");
  const $list = $item.parents(".js-reusability-list");
  const reusability = $item.find("select[name=reusability]").find(":selected").val();

  // Render the item
  $item.replaceWith(
    $.renderTemplate("mediasFormsReusability", {
      right: parseInt(reusability),
      expiration: $item.find(".js-reusability-expiration").length === 0 ? new Date() : undefined,
    })
  );

  // Initialize the Datetime Picker
  $list.find(".js-datetime-picker").initializeDatetimePicker();

  // Check Reusability Validity
  checkReusabilityValidity();
});
