import { html } from "@codemirror/lang-html";
import { oneDark } from "@codemirror/theme-one-dark";
import { EditorView, basicSetup } from "codemirror";
import $ from "jquery";

const codemirrorDataKey = "lesjoursCodemirror";
const eventsNamespace = "lesjours.codemirror";

function codeMirrorExtensions() {
  const extensions = [basicSetup, EditorView.lineWrapping, html({ matchClosingTags: true, autoCloseTags: true })];

  if (document.documentElement.getAttribute("data-bs-theme") === "dark") {
    extensions.push(oneDark);
  }

  return extensions;
}

// Initialize CodeMirror for the current element.
// This method return the created CodeMirror object.
$.fn.createCodeMirror = function () {
  this.data(
    codemirrorDataKey,
    new EditorView({
      doc: "",
      extensions: codeMirrorExtensions(),
      parent: this.get(0),
    })
  );

  return this;
};

// Initialize CodeMirror for the current element.
// This method return the created CodeMirror object.
$.fn.createCodeMirrorFromTextArea = function () {
  // Show the EditorView in the textarea's parent
  this.data(
    codemirrorDataKey,
    new EditorView({
      doc: this.val(),
      extensions: codeMirrorExtensions(),
      parent: this.parent().get(0),
    })
  );

  // Hide the textarea
  this.hide();

  // On submit events on the textarea's form we need to copy the CodeMirror EditorView to the textarea
  this.parents("form").on(`click.${eventsNamespace}`, "[type=submit]", () => {
    // Update the textarea
    this.val(this.getCodeMirrorDoc());
  });

  // Return the textarea
  return this;
};

$.fn.destroyCodeMirror = function () {
  // Destroy the CodeMirror EditorView & remove the data
  this.data(codemirrorDataKey).destroy();
  this.removeData(codemirrorDataKey);
};

$.fn.getCodeMirrorEditorView = function () {
  // Get the CodeMirror EditorView
  return this.data(codemirrorDataKey);
};

$.fn.setCodeMirrorDoc = function (doc) {
  // Get the CodeMirror EditorView
  const CodeMirror = this.data(codemirrorDataKey);

  // Send a change transaction to replace the editor content
  CodeMirror.dispatch({
    changes: { from: 0, to: CodeMirror.state.doc.length, insert: doc },
  });
};

$.fn.getCodeMirrorDoc = function () {
  // Get the CodeMirror EditorView
  const CodeMirror = this.data(codemirrorDataKey);

  // Return the CodeMirror document
  return CodeMirror.state.doc
    .toJSON()
    .map((line) => line.trim())
    .join("\n");
};
